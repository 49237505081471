import React from "react";
import {
    Text,
    View,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    KeyboardAvoidingView,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import Spinner from "react-native-loading-spinner-overlay";
import * as SecureStore from 'expo-secure-store';
import { alertTimeout, sendWebhook } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';


class ConfigurarPDA extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            numeroPDA: "-1",
            codigo: "-1",
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.getDatos();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    getDatos = async () => {
        this.setSpinnerVisible(true);
        let PDA = "-1";
        let codigo = "-1";
        try {
            const value = await SecureStore.getItemAsync('PDA');
            if (value !== null) {
                PDA = value;
            }
        } catch (error) { }
        try {
            const value = await SecureStore.getItemAsync('CODIGO');
            if (value !== null) {
                codigo = value;
            }
        } catch (error) { }
        this.setState({
            numeroPDA: PDA,
            codigo: codigo
        });
        this.setSpinnerVisible(false);
    }

    setSpinnerVisible = (visible) => {
        this.setState({ spinner: visible });
    }

    goBack = () => {
        const { navigate } = this.props.navigation;
        navigate('Login', {
        });
    }

    bajarNumero = () => {
        if (Number(this.state.numeroPDA) > 0) {
            this.setState({ numeroPDA: "" + (Number(this.state.numeroPDA) - 1) });
        }
    }

    subirNumero = () => {
        this.setState({ numeroPDA: "" + (Number(this.state.numeroPDA) + 1) });
    }

    guardarDatos = async () => {
        this.setSpinnerVisible(true);
        try {
            await SecureStore.setItemAsync('PDA', this.state.numeroPDA);
            await SecureStore.setItemAsync('CODIGO', this.state.codigo);
            this.setSpinnerVisible(false);
            sendWebhook(":bangbang: :white_check_mark:  **Configurada PDA** con " + this.state.numeroPDA +
                " y código OAUTH: (" + this.state.codigo + ") :white_check_mark: :bangbang:");
            setTimeout(() => {
                this.setError({
                    title: 'Guardado!',
                    message: 'PDA registrada correctamente con el número: ' + this.state.numeroPDA + ' y código OAUTH: (' + this.state.codigo + ')',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Volver al login',
                        onPress: () => { this.limpiarError(); this.goBack(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { this.limpiarError(); }
                    }
                });
            }, alertTimeout);
        } catch (error) {
            this.setSpinnerVisible(false);
            setTimeout(() => {
                this.setError({
                    title: 'Error',
                    message: 'Error al guardar el numero: ' + error,
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { this.limpiarError(); this.guardarDatos(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { this.limpiarError(); }
                    }
                });
            }, alertTimeout);
        }
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={styles.viewContainer}>
                        <Text style={styles.viewText}>Configurar PDA</Text>
                    </View>
                    <Text>
                        Codigo OAUTH
                    </Text>
                    <View style={styles.inputRow}>
                        <MaterialCommunityIcons name={"account-key"} size={25} color="#d24040" style={{ alignSelf: 'center', marginLeft: 5 }} />
                        <TextInput
                            placeholder="Introduzca el código OAUTH"
                            placeholderTextColor="#d24040"
                            returnKeyType="next"
                            onSubmitEditing={() => { this.numeroInput.focus(); }}
                            autoCapitalize="none"
                            autoCorrect={false}
                            onChangeText={this.handleUser}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            value={this.state.codigo}
                        />
                    </View>
                    <Text>
                        Número de PDA
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.bajarNumero(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"arrow-down-circle"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca el número de la PDA"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.guardarDatos(); }}
                            onChangeText={this.handleNumero}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            keyboardType={'number-pad'}
                            ref={(input) => this.numeroInput = input}
                            value={this.state.numeroPDA}
                        />
                        <TouchableOpacity
                            onPress={() => { this.subirNumero(); }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <Feather name={"arrow-up-circle"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ height: "15%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.guardarDatos(); }}>
                            <Text style={styles.buttonText}>
                                Guardar
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Configurar PDA',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 25
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    },
    logoContainer: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        maxHeight: '40%'
    },
    logo: {
        maxWidth: '80%',
        flex: 1
    },
});

export default withNavigationFocus(ConfigurarPDA);