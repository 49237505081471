import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { checkUpdates, checkCamera, showHelp } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class MenuVentas extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        }
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            ),
            headerRight: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => { showHelp(this); }} >
                    <MaterialCommunityIcons name={"information-outline"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        checkUpdates();
        checkCamera();
    }

    goBack = () => {
        this.props.navigation.goBack();
    }

    goConsultarArticulo = () => {
        const { navigate } = this.props.navigation;
        navigate('ConsultarArticulo', {
        });
    }

    goNuevaVenta = () => {
        const { navigate } = this.props.navigation;
        navigate('NuevaVenta', {
        });
    }

    goVentasPendientes = () => {
        const { navigate } = this.props.navigation;
        navigate('VentasPendientes', {
        });
    }

    render() {
        return (
            <View style={styles.container}>
                <TouchableOpacity
                    style={[styles.buttonContainer, { height: "20%" }]}
                    onPress={() => { this.goNuevaVenta(); }}>
                    <Text style={styles.buttonText}>
                        Crear nueva venta
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.buttonContainer, { height: "20%" }]}
                    onPress={() => { this.goVentasPendientes(); }}>
                    <Text style={styles.buttonText}>
                        Ventas pendientes
                    </Text>
                </TouchableOpacity>
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        const auxHeaderRight = () => (
            <TouchableOpacity style={{ paddingHorizontal: 15 }}>
                <MaterialCommunityIcons name={"information-outline"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Ventas',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft),
            headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 25
    },
});

export default withNavigationFocus(MenuVentas);