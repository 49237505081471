import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    TextInput,
    Keyboard,
    BackHandler,
    Vibration,
    Picker,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as SecureStore from 'expo-secure-store';
import Spinner from "react-native-loading-spinner-overlay";
import Dialog from "react-native-dialog";
import CustomModal from "react-native-modal";
import {
    alertTimeout,
    getLineasVenta,
    getDatosArticulo,
    getUnicaVenta,
    checkContenedor,
    postLineaPedido,
    getUnicaLineaVenta,
    deleteLineaPedido,
    terminarVenta
} from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class ProcesarVenta extends React.PureComponent {
    constructor(props) {
        super(props);

        const venta = this.props.navigation.getParam('venta', {});

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            arrayLineasVenta: [],
            venta: venta,
            codigoArticulo: "",
            codigoContenedor: "",
            showDialog: false,
            showModal: false,
            cambiarArticulo: {
                cantidad: "",
                codigoArticulo: "",
                contenedor: ""
            },
            ordenLineas: "fecha desc",
            mostrarTodosArticulos: "si",
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            ),
            headerRight: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => {
                        this.showModal(true)
                    }} >
                    <MaterialCommunityIcons name={"settings"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        this.updateOrdenLineas();
        this.updateMostrarArticulos();
        this.getDatos();
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    handleBackPress = () => {
        if (this.state.mostrarEscaner) {
            this.setState({ mostrarEscaner: false });
            return true;
        } else {
            return false;
        }
    }

    goBack = () => {
        if (!this.handleBackPress()) {
            this.goVentas();
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    goVentas = () => {
        const { navigate } = this.props.navigation;
        navigate('MenuVentas', {
        });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleCodigoArticulo = (codigoArticulo) => {
        this.setState({ codigoArticulo: codigoArticulo });
    }

    handleCantidad = (cantidad) => {
        if (cantidad == "") {
            this.setState({
                cambiarArticulo: {
                    cantidad: "",
                    codigoArticulo: this.state.cambiarArticulo.codigoArticulo,
                    contenedor: this.state.cambiarArticulo.contenedor
                }
            });
        } else {
            let cantidadNueva = Number(cantidad.replace(/[^0-9]/g, '').replace(/^[0|\D]*/, ''));
            // if (cantidadNueva <= 0) { cantidadNueva = 1; }
            this.setState({
                cambiarArticulo: {
                    cantidad: String(cantidadNueva),
                    codigoArticulo: this.state.cambiarArticulo.codigoArticulo,
                    contenedor: this.state.cambiarArticulo.contenedor
                }
            });
        }
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoArticulo: data }, () => {
            this.getCodigoArticulo();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    showDialog = (show) => {
        this.setState({ showDialog: show }, () => {
            if (show == false) {
                this.setState({
                    cambiarArticulo: {
                        cantidad: "",
                        codigoArticulo: "",
                        contenedor: ""
                    }
                });
            }
        });
    }

    showModal = (show) => {
        this.setState({ showModal: show });
    }

    esContenedor = (contenedor) => {
        return contenedor.length == 8 && contenedor.charAt(0) == "2";
    }

    ordenarLineas = (linea1, linea2) => {
        if (this.state.ordenLineas.startsWith("alfabeto")) {
            if (this.state.ordenLineas.endsWith("asc")) {
                return linea1.d_un > linea2.d_un;
            } else {
                return linea1.d_un < linea2.d_un;
            }
        } else if (this.state.ordenLineas.startsWith("cantidad")) {
            if (this.state.ordenLineas.endsWith("asc")) {
                return linea1.cantidad > linea2.cantidad;
            } else {
                return linea1.cantidad < linea2.cantidad;
            }
        } else if (this.state.ordenLineas.startsWith("contenedor")) {
            if (this.state.ordenLineas.endsWith("asc")) {
                return linea1.kc_contene > linea2.kc_contene;
            } else {
                return linea1.kc_contene < linea2.kc_contene;
            }
        } else {
            const fechaJunta1 = String(linea1.f_trans).concat(String(linea1.h_trans));
            const fecha1 = new Date(parseInt(fechaJunta1.substring(0, 4)),
                parseInt(fechaJunta1.substring(4, 6)) - 1, parseInt(fechaJunta1.substring(6, 8)),
                parseInt(fechaJunta1.substring(8, 10)), parseInt(fechaJunta1.substring(11, 13)),
                parseInt(fechaJunta1.substring(14, 16)));

            const fechaJunta2 = String(linea2.f_trans).concat(String(linea2.h_trans));
            const fecha2 = new Date(parseInt(fechaJunta2.substring(0, 4)),
                parseInt(fechaJunta2.substring(4, 6)) - 1, parseInt(fechaJunta2.substring(6, 8)),
                parseInt(fechaJunta2.substring(8, 10)), parseInt(fechaJunta2.substring(11, 13)),
                parseInt(fechaJunta2.substring(14, 16)));
            if (this.state.ordenLineas.endsWith("asc")) {
                return (fecha1 > fecha2);
            } else {
                return (fecha1 < fecha2);
            }
        }
    }

    getFormatLineas = () => {
        let lineasTemporal = [...this.state.arrayLineasVenta];
        lineasTemporal.sort(this.ordenarLineas);
        if (this.state.mostrarTodosArticulos == "no" && this.state.codigoContenedor && this.state.codigoContenedor != "") {
            lineasTemporal = lineasTemporal.filter(linea => {
                return linea.kc_contene == this.state.codigoContenedor;
            });
        }
        return lineasTemporal;
    }

    updateOrdenLineas = async () => {
        try {
            const ordenLineas = await SecureStore.getItemAsync("ordenLineas");
            if (ordenLineas !== null && ordenLineas != this.state.ordenLineas) {
                this.setState({
                    ordenLineas: ordenLineas
                });
            }
        } catch (error) {
            console.warn("ERROR updateOrdenLineas: " + error)
        }
    }

    updateMostrarArticulos = async () => {
        try {
            const mostrarTodosArticulos = await SecureStore.getItemAsync("mostrarTodosArticulos");
            if (mostrarTodosArticulos !== null && mostrarTodosArticulos != this.state.mostrarTodosArticulos) {
                this.setState({
                    mostrarTodosArticulos: mostrarTodosArticulos
                });
            }
        } catch (error) {
            console.warn("ERROR updateMostrarTodosArticulos: " + error)
        }
    }

    storeOrdenLineas = async (ordenLineas) => {
        try {
            await SecureStore.setItemAsync("ordenLineas", ordenLineas);
        } catch (error) {
            console.warn("ERROR storeOrdenLineas: " + error)
        }
        // this.getDatos();
    }

    storeMostrarArticulos = async (mostrarTodosArticulos) => {
        try {
            await SecureStore.setItemAsync("mostrarTodosArticulos", mostrarTodosArticulos);
        } catch (error) {
            console.warn("ERROR mostrarTodosArticulos: " + error)
        }
        // this.getDatos();
    }

    getCodigoArticulo = () => {
        Keyboard.dismiss();
        if (this.esContenedor(this.state.codigoArticulo)) {
            const contenedor = this.state.codigoArticulo.slice(1, -1);
            const myThis = this;
            myThis.setSpinnerVisible(true);
            checkContenedor(contenedor, function (respuesta) {
                if (respuesta && respuesta.Contenes && respuesta.Contenes.Contene && respuesta.Contenes.Contene.kc_contene) {
                    myThis.setSpinnerVisible(false);
                    myThis.setState({
                        codigoArticulo: "",
                        codigoContenedor: respuesta.Contenes.Contene.kc_contene
                    }, () => {
                        setTimeout(() => {
                            myThis.articuloInput.focus();
                        }, 550);
                    });
                } else {
                    myThis.setSpinnerVisible(false);
                    setTimeout(() => {
                        myThis.setError({
                            title: 'Error',
                            message: 'Contenedor no encontrado.',
                            visible: true,
                            onTouchOutside: () => { },
                            positiveButton: {
                                title: 'OK',
                                onPress: () => {
                                    myThis.limpiarError();
                                    setTimeout(() => {
                                        myThis.articuloInput.focus();
                                    }, 550);
                                }
                            }
                        });
                    }, alertTimeout);
                }
            }, function (error) {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error en la comunicación con el servidor (-201)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.getArticulo(); }
                        },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => {
                                myThis.limpiarError();
                                setTimeout(() => {
                                    myThis.articuloInput.focus();
                                }, 550);
                            }
                        }
                    });
                }, alertTimeout);
            });
        } else {
            if (!this.state.codigoContenedor || this.state.codigoContenedor == "") {
                setTimeout(() => {
                    this.setError({
                        title: 'ATENCION!',
                        message: 'Para comenzar a añadir artículos es necesario escanear primero el CONTENEDOR.',
                        visible: true,
                        onTouchOutside: () => { },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => {
                                this.limpiarError();
                                setTimeout(() => {
                                    this.articuloInput.focus();
                                }, 550);
                            }
                        }
                    });
                }, alertTimeout);
            } else {
                const myThis = this;
                myThis.setSpinnerVisible(true);
                getDatosArticulo(myThis.state.codigoArticulo, function (respuesta) {
                    if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "5201"
                        && respuesta.Mensaje.Articulo && respuesta.Mensaje.Articulo.CodArt) {
                        let cantidadNueva = 1;
                        getUnicaLineaVenta(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb,
                            myThis.state.codigoContenedor, respuesta.Mensaje.Articulo.CodArt, function (respuestaLinea) {
                                if (respuestaLinea && respuestaLinea.LPedidosPDA && respuestaLinea.LPedidosPDA.LPedidoPDA
                                    && respuestaLinea.LPedidosPDA.LPedidoPDA.cantidad) {
                                    cantidadNueva = respuestaLinea.LPedidosPDA.LPedidoPDA.cantidad + 1;
                                }
                                postLineaPedido(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb,
                                    myThis.state.codigoContenedor, respuesta.Mensaje.Articulo.CodArt, cantidadNueva,
                                    function (respuestaPost) {
                                        if (respuestaPost && respuestaPost.API_Status && respuestaPost.API_Status.Request_Status &&
                                            respuestaPost.API_Status.Request_Status.toLowerCase() == "successful") {
                                            myThis.setSpinnerVisible(false);
                                            myThis.getDatos();
                                        } else {
                                            myThis.setSpinnerVisible(false);
                                            setTimeout(() => {
                                                myThis.setError({
                                                    title: 'Error al actualizar línea',
                                                    message: 'Error inesperado añadiendo artículo a la línea',
                                                    visible: true,
                                                    onTouchOutside: () => { },
                                                    negativeButton: {
                                                        title: 'Reintentar',
                                                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                                    },
                                                    positiveButton: {
                                                        title: 'OK',
                                                        onPress: () => { myThis.limpiarError(); }
                                                    }
                                                });
                                            }, alertTimeout);
                                        }
                                    }, function (error) {
                                        myThis.setSpinnerVisible(false);
                                        setTimeout(() => {
                                            myThis.setError({
                                                title: 'Error',
                                                message: 'Error en la comunicación con el servidor (-201)',
                                                visible: true,
                                                onTouchOutside: () => { },
                                                negativeButton: {
                                                    title: 'Reintentar',
                                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                                },
                                                positiveButton: {
                                                    title: 'OK',
                                                    onPress: () => { myThis.limpiarError(); }
                                                }
                                            });
                                        }, alertTimeout);
                                    });
                            }, function (error) {
                                myThis.setSpinnerVisible(false);
                                setTimeout(() => {
                                    myThis.setError({
                                        title: 'Error',
                                        message: 'Error en la comunicación con el servidor (-201)',
                                        visible: true,
                                        onTouchOutside: () => { },
                                        negativeButton: {
                                            title: 'Reintentar',
                                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                        },
                                        positiveButton: {
                                            title: 'OK',
                                            onPress: () => { myThis.limpiarError(); }
                                        }
                                    });
                                }, alertTimeout);
                            });
                    } else {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error en artículo',
                                message: 'Artículo no encontrado (' + myThis.state.codigoArticulo + ")",
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                },
                                positiveButton: {
                                    title: 'OK',
                                    onPress: () => { myThis.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    }
                }, function (error) {
                    myThis.setSpinnerVisible(false);
                    setTimeout(() => {
                        myThis.setError({
                            title: 'Error',
                            message: 'Error en la comunicación con el servidor (-201)',
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: 'Reintentar',
                                onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                            },
                            positiveButton: {
                                title: 'OK',
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                });
            }
        }
    }

    getDatos = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.showDialog(false);
        myThis.setSpinnerVisible(true);

        getUnicaVenta(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb, function (respuestaUnicaVenta) {
            if (respuestaUnicaVenta && respuestaUnicaVenta.PedidosPDA && respuestaUnicaVenta.PedidosPDA.PedidoPDA) {
                getLineasVenta(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb, function (respuestaLineas) {
                    myThis.setSpinnerVisible(false);
                    if (respuestaLineas && respuestaLineas.LPedidosPDA && respuestaLineas.LPedidosPDA.LPedidoPDA
                        && Array.isArray(respuestaLineas.LPedidosPDA.LPedidoPDA)) {
                        myThis.setState({
                            arrayLineasVenta: respuestaLineas.LPedidosPDA.LPedidoPDA
                        });
                    } else {
                        myThis.setState({
                            arrayLineasVenta: []
                        });
                    }
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                }, function (error) {
                    myThis.setSpinnerVisible(false);
                    setTimeout(() => {
                        myThis.setError({
                            title: 'Error',
                            message: 'Error en la comunicación con el servidor (-401)',
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: 'Reintentar',
                                onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                            },
                            positiveButton: {
                                title: 'OK',
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                });
            } else {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error con la Venta',
                        message: 'Venta no encontrada o ya terminada',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                        },
                        positiveButton: {
                            title: 'Volver a Ventas',
                            onPress: () => { myThis.limpiarError(); myThis.goVentas(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    cambiarCantidad = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        if (Number(myThis.state.cambiarArticulo.cantidad > 0)) {
            postLineaPedido(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb, myThis.state.cambiarArticulo.contenedor,
                myThis.state.cambiarArticulo.codigoArticulo, myThis.state.cambiarArticulo.cantidad,
                function (respuestaPost) {
                    if (respuestaPost && respuestaPost.API_Status && respuestaPost.API_Status.Request_Status &&
                        respuestaPost.API_Status.Request_Status.toLowerCase() == "successful") {
                        myThis.setSpinnerVisible(false);
                        myThis.showDialog(false);
                        myThis.getDatos();
                    } else {
                        myThis.setSpinnerVisible(false);
                        myThis.showDialog(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error al actualizar línea',
                                message: 'Error inesperado editando cantidad de la línea',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                },
                                positiveButton: {
                                    title: 'OK',
                                    onPress: () => { myThis.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    }
                }, function (error) {
                    myThis.setSpinnerVisible(false);
                    myThis.showDialog(false);
                    setTimeout(() => {
                        myThis.setError({
                            title: 'Error',
                            message: 'Error en la comunicación con el servidor (-201)',
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: 'Reintentar',
                                onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                            },
                            positiveButton: {
                                title: 'OK',
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                });
        } else if (myThis.state.cambiarArticulo.cantidad == "" || Number(myThis.state.cambiarArticulo.cantidad) == 0) {
            deleteLineaPedido(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb,
                myThis.state.cambiarArticulo.contenedor, myThis.state.cambiarArticulo.codigoArticulo,
                function (respuestaDelete) {
                    if (respuestaDelete && respuestaDelete.API_Status && respuestaDelete.API_Status.Request_Status &&
                        respuestaDelete.API_Status.Request_Status.toLowerCase() == "successful") {
                        myThis.setSpinnerVisible(false);
                        myThis.showDialog(false);
                        myThis.getDatos();
                    } else {
                        myThis.setSpinnerVisible(false);
                        myThis.showDialog(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error al actualizar línea',
                                message: 'Error inesperado eliminando la línea',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                },
                                positiveButton: {
                                    title: 'OK',
                                    onPress: () => { myThis.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    }
                }, function (error) {
                    myThis.setSpinnerVisible(false);
                    myThis.showDialog(false);
                    setTimeout(() => {
                        myThis.setError({
                            title: 'Error',
                            message: 'Error en la comunicación con el servidor (-201)',
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: 'Reintentar',
                                onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                            },
                            positiveButton: {
                                title: 'OK',
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                });
        } else {
            myThis.setSpinnerVisible(false);
            myThis.showDialog(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error al actualizar línea',
                    message: 'Error inesperado actualizando la línea',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        }
    }

    pushCantidad = (linea) => {
        this.setState({
            cambiarArticulo: {
                cantidad: String(linea.cantidad),
                codigoArticulo: String(linea.k_un),
                contenedor: String(linea.kc_contene)
            }
        }, () => {
            this.showDialog(true);
        });
    }

    pushArticulo = (linea) => {
        setTimeout(() => {
            const fechaJunta = String(linea.f_trans).concat(String(linea.h_trans));
            const fecha = fechaJunta.substring(0, 4) + "/" + fechaJunta.substring(4, 6) + "/" +
                fechaJunta.substring(6, 8) + " - " + fechaJunta.substring(8, 16);

            const articulo = "\nCódigo de artículo: " + linea.k_un
                + "\n\nDescripción: " + linea.d_un
                + "\n\nContenedor: " + linea.kc_contene
                + "\n\nÚltima actualización: " + fecha;
            this.setError({
                title: 'Detalles de artículo',
                message: articulo,
                visible: true,
                onTouchOutside: () => { },
                positiveButton: {
                    title: 'OK',
                    onPress: () => { this.limpiarError(); }
                }
            });
        }, alertTimeout);
    }

    terminarVenta = () => {
        Keyboard.dismiss();
        const myThis = this;

        setTimeout(() => {
            myThis.setError({
                title: 'Terminando Venta',
                message: 'Se va a terminar la venta del cliente: ' + myThis.state.venta.alias.trim(),
                visible: true,
                onTouchOutside: () => { },
                negativeButton: {
                    title: 'Cancelar',
                    onPress: () => { myThis.limpiarError(); }
                },
                positiveButton: {
                    title: 'Confirmar',
                    onPress: () => {
                        myThis.setSpinnerVisible(true);
                        terminarVenta(myThis.state.venta.k_cliente, myThis.state.venta.kc_pedweb, function (respuesta) {
                            if (respuesta && respuesta.API_Status && respuesta.API_Status.Request_Status &&
                                respuesta.API_Status.Request_Status.toLowerCase() == "successful") {
                                myThis.setSpinnerVisible(false);
                                setTimeout(() => {
                                    myThis.setError({
                                        title: 'Venta terminada',
                                        message: 'Venta del cliente (' + myThis.state.venta.alias.trim() + ") terminada correctamente.",
                                        visible: true,
                                        onTouchOutside: () => { },
                                        positiveButton: {
                                            title: 'OK',
                                            onPress: () => { myThis.limpiarError(); myThis.goVentas(); }
                                        }
                                    });
                                }, alertTimeout);
                            } else {
                                myThis.setSpinnerVisible(false);
                                let error = '';
                                if (respuesta && respuesta.API_Status && respuesta.API_Status.Request_Status) {
                                    error = ' (' + respuesta.API_Status.Request_Status + ')'
                                }
                                setTimeout(() => {
                                    myThis.setError({
                                        title: 'Error al terminar venta',
                                        message: 'Error inesperado terminando venta' + error,
                                        visible: true,
                                        onTouchOutside: () => { },
                                        negativeButton: {
                                            title: 'Reintentar',
                                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                        },
                                        positiveButton: {
                                            title: 'OK',
                                            onPress: () => { myThis.limpiarError(); }
                                        }
                                    });
                                }, alertTimeout);
                            }
                        }, function (error) {
                            myThis.setSpinnerVisible(false);
                            setTimeout(() => {
                                myThis.setError({
                                    title: 'Error',
                                    message: 'Error en la comunicación con el servidor (-401)',
                                    visible: true,
                                    onTouchOutside: () => { },
                                    negativeButton: {
                                        title: 'Reintentar',
                                        onPress: () => { myThis.limpiarError(); myThis.terminarVenta(); }
                                    },
                                    positiveButton: {
                                        title: 'OK',
                                        onPress: () => { myThis.limpiarError(); }
                                    }
                                });
                            }, alertTimeout);
                        });
                    }
                }
            });
        }, alertTimeout);
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de barras
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        let lineas = [];
        if (this.state.arrayLineasVenta) {
            if (this.state.arrayLineasVenta.length == 0) {
                lineas.push(<View
                    style={{ borderBottomWidth: 1, }}
                    key={0}>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15, marginVertical: 15 }}>
                            Esta venta no tiene líneas
                        </Text>
                    </View>
                </View>);
            } else {
                let arrayLineas = this.getFormatLineas();
                if (arrayLineas.length == 0) {
                    lineas.push(<View
                        style={{ borderBottomWidth: 1, }}
                        key={0}>
                        <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                            <Text style={{ fontSize: 15, marginVertical: 15 }}>
                                Este contenedor no tiene líneas
                            </Text>
                        </View>
                    </View>);
                } else {
                    arrayLineas.forEach((linea, contador) => {
                        lineas.push(<View
                            style={{ borderBottomWidth: 1, }}
                            key={contador}>
                            <View style={[{ flexDirection: "row" }]}>
                                <View style={{ width: "85%" }}>
                                    {this.state.codigoContenedor == "" ||
                                        (this.state.codigoContenedor != "" && this.state.mostrarTodosArticulos == "si") ?
                                        <Text style={{ fontSize: 8, position: "absolute", paddingLeft: 5, paddingTop: 5 }}>
                                            {linea.kc_contene}
                                        </Text> : <View></View>}
                                    <TouchableOpacity
                                        onPress={() => { this.pushArticulo(linea) }}
                                        style={{ flex: 1, paddingHorizontal: 5 }}>
                                        <Text style={{ fontSize: 20, marginVertical: 15 }}>
                                            {linea.d_un}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ width: "15%" }}>
                                    <TouchableOpacity
                                        onPress={() => { this.pushCantidad(linea) }}
                                        style={{ flex: 1, borderLeftWidth: 1, justifyContent: "center", alignItems: "center" }}>
                                        <Text style={{ fontSize: 20, marginVertical: 15, textAlign: "center" }}>
                                            {linea.cantidad}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>);
                    });
                }
            }
        }
        const listKey = "".concat(this.state.ordenLineas).concat(this.state.mostrarTodosArticulos);
        return (
            <View style={styles.container}>
                <Dialog.Container
                    visible={this.state.showDialog}>
                    <Dialog.Title>Editar Cantidad</Dialog.Title>
                    <Dialog.Description>
                        Edite manualmente la cantidad del artículo.
                    </Dialog.Description>
                    <Dialog.Input
                        label="Cantidad"
                        style={[styles.inputDialog]}
                        placeholder="Introduzca cantidad"
                        placeholderTextColor="#d24040"
                        onSubmitEditing={() => { this.cambiarCantidad(); }}
                        underlineColorAndroid={'transparent'}
                        keyboardType={'number-pad'}
                        onChangeText={this.handleCantidad}
                        value={this.state.cambiarArticulo.cantidad}>
                    </Dialog.Input>
                    <Dialog.Button
                        label="Cancelar"
                        color="#d24040"
                        onPress={() => { this.showDialog(false); }} />
                    <Dialog.Button
                        label="Confirmar"
                        color="#d24040"
                        bold={true}
                        onPress={() => { this.cambiarCantidad(); }} />
                </Dialog.Container>
                <CustomModal
                    style={styles.bottomModal}
                    isVisible={this.state.showModal}
                    onBackButtonPress={() => { this.showModal(false); }}
                    onBackdropPress={() => { this.showModal(false); }}
                    onModalHide={() => {
                        this.storeOrdenLineas(this.state.ordenLineas);
                        this.storeMostrarArticulos(this.state.mostrarTodosArticulos);
                    }}>
                    <View style={{ height: "auto", backgroundColor: "#FFF", alignItems: "center", paddingVertical: 20 /*justifyContent: "center"*/ }}>
                        <Text style={{ color: "#000", width: "100%", textAlign: "center" }}>
                            Ordenar líneas por:
                        </Text>
                        <Picker
                            selectedValue={this.state.ordenLineas}
                            style={{ height: 50, width: "55%", marginBottom: 30, justifyContent: 'center', }}
                            onValueChange={(itemValue, itemIndex) => {
                                this.setState({ ordenLineas: itemValue });
                            }}>
                            <Picker.Item label="Fecha ↑" value="fecha asc" />
                            <Picker.Item label="Fecha ↓" value="fecha desc" />
                            <Picker.Item label="Cantidad ↑" value="cantidad asc" />
                            <Picker.Item label="Cantidad ↓" value="cantidad desc" />
                            <Picker.Item label="Contenedor ↑" value="contenedor asc" />
                            <Picker.Item label="Contenedor ↓" value="contenedor desc" />
                            <Picker.Item label="Alfabéticamente ↑" value="alfabeto asc" />
                            <Picker.Item label="Alfabéticamente ↓" value="alfabeto desc" />
                        </Picker>
                        <Text style={{ color: "#000", width: "100%", textAlign: "center" }}>
                            ¿Mostrar sólo líneas del contenedor actual?:
                        </Text>
                        <Picker
                            selectedValue={this.state.mostrarTodosArticulos}
                            style={{ height: 50, width: "55%", marginBottom: 30, justifyContent: 'center', }}
                            onValueChange={(itemValue, itemIndex) => {
                                this.setState({ mostrarTodosArticulos: itemValue });
                            }}>
                            <Picker.Item label="SI" value="no" />
                            <Picker.Item label="NO" value="si" />
                        </Picker>
                    </View>
                </CustomModal>

                <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "50%", padding: 10 }}>
                        <Text style={{ justifyContent: "center", fontSize: 12 }}>Cliente {this.state.venta.k_cliente}:</Text>
                        <Text style={{ fontWeight: '700', fontSize: 20 }}>{this.state.venta.alias.trim()}</Text>
                    </View>
                    <View style={{ width: "50%", padding: 10 }}>
                        <TouchableOpacity
                            style={[{
                                paddingVertical: 10,
                                backgroundColor: '#DC143C',
                                borderWidth: 1,
                                borderRadius: 5,
                                borderColor: '#DC143C',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }]}
                            onPress={() => { this.terminarVenta(); }}>
                            <Text style={{ color: '#fff', fontWeight: '700', fontSize: 20 }}>
                                Terminar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.inputRow}>
                    <TouchableOpacity
                        onPress={() => { this.scanCode(); }}
                        style={{ alignSelf: 'center', marginLeft: 5 }}>
                        <Feather name={"camera"} size={30} color="#d24040" />
                    </TouchableOpacity>
                    <TextInput
                        placeholder="Escanear código de barras"
                        placeholderTextColor="#d24040"
                        returnKeyType="go"
                        onSubmitEditing={() => { this.getCodigoArticulo(); }}
                        onChangeText={this.handleCodigoArticulo}
                        style={styles.input}
                        underlineColorAndroid={'transparent'}
                        ref={(input) => this.articuloInput = input}
                        selectTextOnFocus
                        value={this.state.codigoArticulo}
                    />
                    <TouchableOpacity
                        onPress={() => {
                            this.handleCodigoArticulo("");
                        }}
                        style={{ alignSelf: 'center', marginRight: 5 }}>
                        <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                    </TouchableOpacity>
                </View>

                <View>
                    <View style={{ flexDirection: "row" }}>
                        <View>
                            <Text>
                                Contenedor: {this.state.codigoContenedor != "" ? <Text style={{ fontWeight: "700" }}>
                                    {this.state.codigoContenedor}
                                </Text> : <Text style={{ fontWeight: "700" }}>NO ESTABLECIDO</Text>}
                            </Text>
                            <Text>
                                Líneas <Text style={{ fontWeight: "700" }}>({this.state.arrayLineasVenta.length})</Text>
                            </Text>
                            {this.state.codigoContenedor != "" && this.state.mostrarTodosArticulos == "no" ?
                                <Text>Mostrando sólo las del contenedor actual</Text> : <View></View>}
                        </View>
                        <View style={{ flex: 1, alignItems: "flex-end", alignSelf: "center" }}>
                            <TouchableOpacity
                                style={{ paddingHorizontal: 15 }}
                                onPress={() => {
                                    this.getDatos();
                                }}>
                                <MaterialCommunityIcons name={"reload"} size={30} color="#000" />
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={{ height: "68%", borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 2 }}>
                        <ScrollView
                            key={listKey} >
                            {lineas}
                        </ScrollView>
                    </View>
                </View>
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        const auxHeaderRight = () => (
            <TouchableOpacity style={{ paddingHorizontal: 15 }}>
                <MaterialCommunityIcons name={"settings"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Líneas de Venta',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft),
            headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 25
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    },
    inputDialog: {
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        color: "#d24040"
    },
    bottomModal: {
        justifyContent: "flex-end",
        margin: 0
    },
});

export default withNavigationFocus(ProcesarVenta);