import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { checkUpdates, checkCamera } from "../Utility/Api";

class MenuUbicaciones extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        checkUpdates();
        checkCamera();
    }

    goBack = () => {
        this.props.navigation.goBack();
    }

    goUbicar = () => {
        const { navigate } = this.props.navigation;
        navigate('Ubicar', {
        });
    }

    goDesubicar = () => {
        const { navigate } = this.props.navigation;
        navigate('Desubicar', {
        });
    }

    goConsultarArticuloUbi = () => {
        const { navigate } = this.props.navigation;
        navigate('ConsultarArticuloUbi', {
        });
    }

    goConsultarUbicacion = () => {
        const { navigate } = this.props.navigation;
        navigate('ConsultarUbicacion', {
        });
    }

    goMover = () => {
        const { navigate } = this.props.navigation;
        navigate('Mover', {
        });
    }

    render() {
        return (
            <View style={styles.container}>
                <TouchableOpacity
                    style={[styles.buttonContainer, { height: "15%" }]}
                    onPress={() => { this.goUbicar(); }}>
                    <Text style={styles.buttonText}>
                        Ubicar
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.buttonContainer, { height: "15%" }]}
                    onPress={() => { this.goDesubicar(); }}>
                    <Text style={styles.buttonText}>
                        Desubicar
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.buttonContainer, { height: "15%" }]}
                    onPress={() => { this.goMover(); }}>
                    <Text style={styles.buttonText}>
                        Mover
                    </Text>
                </TouchableOpacity>
                <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: 'center', }}>
                    <TouchableOpacity
                        style={[styles.buttonContainer, { width: "48%", marginHorizontal: 5 }]}
                        onPress={() => { this.goConsultarArticuloUbi(); }}>
                        <Text style={[styles.buttonText, { fontSize: 15 }]}>
                            Consultar Artículo
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.buttonContainer, { width: "48%", marginHorizontal: 5 }]}
                        onPress={() => { this.goConsultarUbicacion(); }}>
                        <Text style={[styles.buttonText, { fontSize: 15 }]}>
                            Consultar Ubicación
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Menu Ubicaciones',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
            //headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 25
    },
});

export default withNavigationFocus(MenuUbicaciones);