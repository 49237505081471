import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    TextInput,
    Keyboard,
    BackHandler,
    Vibration,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import Spinner from "react-native-loading-spinner-overlay";
import { alertTimeout, getDatosArticuloUbi, sendWebhook } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class ConsultarArticuloUbi extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            codigoArticulo: "",
            nombreArticulo: "",
            ubicaciones: [],
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        setTimeout(() => {
            this.articuloInput.focus();
        }, 550);
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleBackPress = () => {
        if (this.state.mostrarEscaner) {
            this.setState({ mostrarEscaner: false });
            return true;
        } else {
            return false;
        }
    }

    goBack = () => {
        if (!this.handleBackPress()) {
            this.props.navigation.goBack();
        }
    }

    handleCodigoArticulo = (codigoArticulo) => {
        this.setState({ codigoArticulo: codigoArticulo });
    }

    getArticuloUbi = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        sendWebhook(":mag_right: Consultando artículo (ubicaciones): " + this.state.codigoArticulo);
        getDatosArticuloUbi(this.state.codigoArticulo, function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.Mensaje && respuesta.Mensaje.DesArt) {
                let arrayUbicaciones = [];
                if (respuesta.Mensaje.Ubicaciones && respuesta.Mensaje.Ubicaciones.Ubicacion
                    && Array.isArray(respuesta.Mensaje.Ubicaciones.Ubicacion)) {
                    arrayUbicaciones = JSON.parse(JSON.stringify(respuesta.Mensaje.Ubicaciones.Ubicacion).replace(/\s/g, ''));
                }
                myThis.setState({
                    nombreArticulo: respuesta.Mensaje.DesArt,
                    ubicaciones: arrayUbicaciones
                }, () => {
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                });
            } else {
                myThis.setState({
                    nombreArticulo: "NO ENCONTRADO",
                    ubicaciones: []
                }, () => {
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                });
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getArticuloUbi(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoArticulo: data }, () => {
            this.getArticuloUbi();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de barras
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        let ubicaciones = [];
        if (this.state.ubicaciones) {
            this.state.ubicaciones.forEach((ubicacion, contador) => {
                ubicaciones.push(<View
                    style={{ borderBottomWidth: 1, }}
                    key={contador}>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Ubicacion:</Text>
                        <Text style={{ fontSize: 17, paddingLeft: 15, maxWidth: 210 }}>{ubicacion.Ubica}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Existencias:</Text>
                        <Text style={{ fontSize: 17, paddingLeft: 15, maxWidth: 210 }}>{ubicacion.Existencias}</Text>
                    </View>
                </View>);
            });
        }
        return (
            <View style={styles.container}>
                <View style={styles.viewContainer}>
                    <Text style={styles.viewText}>Consulta datos artículo</Text>
                </View>
                <Text>
                    Artículo
                </Text>
                <View style={styles.inputRow}>
                    <TouchableOpacity
                        onPress={() => { this.scanCode(); }}
                        style={{ alignSelf: 'center', marginLeft: 5 }}>
                        <Feather name={"camera"} size={30} color="#d24040" />
                    </TouchableOpacity>
                    <TextInput
                        placeholder="Introduzca código de artículo"
                        placeholderTextColor="#d24040"
                        returnKeyType="go"
                        onSubmitEditing={() => { this.getArticuloUbi(); }}
                        onChangeText={this.handleCodigoArticulo}
                        style={styles.input}
                        underlineColorAndroid={'transparent'}
                        ref={(input) => this.articuloInput = input}
                        selectTextOnFocus
                        value={this.state.codigoArticulo}
                    />
                    <TouchableOpacity
                        onPress={() => {
                            this.setState({ nombreArticulo: "", codigoArticulo: "", ubicaciones: [] });
                            this.handleCodigoArticulo("");
                        }}
                        style={{ alignSelf: 'center', marginRight: 5 }}>
                        <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                    </TouchableOpacity>
                </View>
                {this.state.nombreArticulo ? <View style={{}}>
                    <Text>
                        Nombre
                    </Text>
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>{this.state.nombreArticulo}</Text>
                        </View>
                    </View>
                </View> : <View></View>}

                {this.state.nombreArticulo ? <View style={{ marginTop: 20 }}>
                    <Text>
                        Ubicaciones <Text style={{ fontWeight: "700" }}>({this.state.ubicaciones.length})</Text>
                    </Text>
                    <View style={{ height: "65%", borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 2 }}>
                        <ScrollView>{ubicaciones}</ScrollView>
                    </View>
                </View> : <View></View>}
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Consultar Artículo',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
            //headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 25
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    }
});

export default withNavigationFocus(ConsultarArticuloUbi);