import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    Keyboard,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import Spinner from "react-native-loading-spinner-overlay";
import { alertTimeout, getVentas } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class VentasPendientes extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            arrayVentas: [],
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            ),
            headerRight: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => { this.getDatos(); }} >
                    <MaterialCommunityIcons name={"reload"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.getDatos();
    }

    goBack = () => {
        this.props.navigation.goBack();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    pushVenta = (venta) => {
        const { navigate } = this.props.navigation;
        navigate('ProcesarVenta', {
            venta: venta
        });
    }

    getDatos = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getVentas(function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.PedidosPDA && respuesta.PedidosPDA.PedidoPDA) {
                let arrayVentas = [];
                if (Array.isArray(respuesta.PedidosPDA.PedidoPDA)) {
                    //arrayVentas = JSON.parse(JSON.stringify(respuesta.PedidosPDA.PedidoPDA).replace(/\s/g, ''));
                    arrayVentas = respuesta.PedidosPDA.PedidoPDA;
                }
                myThis.setState({
                    arrayVentas: arrayVentas
                });
            } else {
                myThis.setState({
                    arrayVentas: []
                });
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    render() {
        let ventas = [];
        if (this.state.arrayVentas) {
            if (this.state.arrayVentas.length == 0) {
                ventas.push(<View
                    style={{ borderBottomWidth: 1, }}
                    key={0}>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15, marginVertical: 15 }}>
                            Este usuario no tiene ventas pendientes
                        </Text>
                    </View>
                </View>);
            } else {
                this.state.arrayVentas.forEach((venta, contador) => {
                    ventas.push(<View
                        style={{ borderBottomWidth: 1, }}
                        key={contador}>
                        <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                            <TouchableOpacity
                                onPress={() => { this.pushVenta(venta) }}
                                style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                <Text style={{ fontSize: 25, marginVertical: 15 }}>{venta.alias.trim()}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>);
                });
            }
        }
        return (
            <View style={styles.container}>
                <View style={styles.viewContainer}>
                    <Text style={styles.viewText}>Ventas pendientes de terminar</Text>
                </View>

                <View style={{ marginTop: 20 }}>
                    <Text>
                        Ventas <Text style={{ fontWeight: "700" }}>({this.state.arrayVentas.length})</Text>
                    </Text>
                    <View style={{ height: "85%", borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 2 }}>
                        <ScrollView>{ventas}</ScrollView>
                    </View>
                </View>
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        const auxHeaderRight = () => (
            <TouchableOpacity style={{ paddingHorizontal: 15 }}>
                <MaterialCommunityIcons name={"reload"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Ventas pendientes',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft),
            headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 22
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    }
});

export default withNavigationFocus(VentasPendientes);