import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    TextInput,
    Keyboard,
    BackHandler,
    Vibration,
    Image,
    KeyboardAvoidingView,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import Spinner from "react-native-loading-spinner-overlay";
import DialogInput from 'react-native-dialog-input';
import Dialog from "react-native-dialog";
import * as SecureStore from 'expo-secure-store';
import {
    alertTimeout,
    getOrden,
    getPale,
    getArticuloCajas,
    getUbicacionCajas,
    getSacarArticulosPale,
    getMeterArticulosPale,
    getPesarLleno,
    getCompararUbicaciones,
    getIntroPaleUbi,
    getTerminarLinea,
    getTerminarOrden
} from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class ProcesarOrdenes extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            codigoOrden: "", //TODO QUITAR
            ordenCorrecta: true,
            arrayOrdenes: [],
            posicionArrayOrdenes: 0,
            numeroOrden: 0,
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        setTimeout(() => {
            this.ordenInput.focus();
        }, 550);
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleBackPress = () => {
        if (this.state.mostrarEscaner) { //TODO OR mostrarEscanerEnOrden
            this.setState({ mostrarEscaner: false });
            return true;
        } else {
            return false;
        }
    }

    goBack = () => {
        if (!this.handleBackPress()) {
            this.props.navigation.goBack();
        }
    }

    handleCodigoOrden = (codigoOrden) => {
        this.setState({ codigoOrden: codigoOrden });
    }

    setPosicionArrayOrdenes = (posicion) => {
        this.setState({
            posicionArrayOrdenes: posicion
        });
    }

    comprobarOrden = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getOrden(this.state.codigoOrden, function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1001") {
                let posicionArrayOrdenes = 0;
                for (let i = 0; i < respuesta.Mensaje.Orden.LOrden.length; i++) {
                    const element = respuesta.Mensaje.Orden.LOrden[i];
                    if (element.LHOrdExpe == "002") {
                        posicionArrayOrdenes++;
                    } else {
                        break;
                    }
                }
                myThis.setState({
                    ordenCorrecta: true,
                    arrayOrdenes: respuesta.Mensaje.Orden.LOrden,
                    posicionArrayOrdenes: posicionArrayOrdenes,
                    numeroOrden: respuesta.Mensaje.Orden.Numero
                });
            } else {
                myThis.setState({
                    ordenCorrecta: false
                }, () => {
                    setTimeout(() => {
                        myThis.ordenInput.focus();
                    }, 550);
                });
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.comprobarOrden(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoOrden: data }, () => {
            this.comprobarOrden();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    render() {
        if (this.state.posicionArrayOrdenes == this.state.arrayOrdenes.length && this.state.posicionArrayOrdenes != 0) {
            setTimeout(() => {
                this.setError({
                    title: 'Orden Terminada',
                    message: 'Orden numero (' + this.state.codigoOrden + ') terminada',
                    visible: true,
                    onTouchOutside: () => { },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => {
                            this.limpiarError();
                            this.setState({
                                mostrarEscaner: false,
                                spinner: false,
                                codigoOrden: "",
                                ordenCorrecta: true,
                                arrayOrdenes: [],
                                posicionArrayOrdenes: 0,
                                numeroOrden: 0
                            }, () => {
                                this.ordenInput.focus();
                            });
                        }
                    }
                });
            }, alertTimeout);
        } else if (this.state.posicionArrayOrdenes < this.state.arrayOrdenes.length && this.state.arrayOrdenes.length > 0) {
            const orden = this.state.arrayOrdenes[this.state.posicionArrayOrdenes]; //TODO QUITAR +1
            console.warn("Posicion: " + this.state.posicionArrayOrdenes + " , Oper: " + orden.Oper);
            switch (orden.Oper) {
                case "001":
                    return <OrdenPesarVacio
                        key={this.state.numeroOrden}
                        orden={orden}
                        posicion={this.state.posicionArrayOrdenes}
                        setPosicionArrayOrdenes={this.setPosicionArrayOrdenes}
                        numeroOrden={this.state.numeroOrden}
                        goBack={this.goBack}
                    />;
                case "004":
                    return <OrdenPasarCajas
                        key={this.state.numeroOrden}
                        orden={orden}
                        posicion={this.state.posicionArrayOrdenes}
                        setPosicionArrayOrdenes={this.setPosicionArrayOrdenes}
                        numeroOrden={this.state.numeroOrden}
                        goBack={this.goBack}
                    />;
                case "005":
                    return <OrdenPesarLleno
                        key={this.state.numeroOrden}
                        orden={orden}
                        posicion={this.state.posicionArrayOrdenes}
                        setPosicionArrayOrdenes={this.setPosicionArrayOrdenes}
                        numeroOrden={this.state.numeroOrden}
                        goBack={this.goBack}
                    />;
                case "003":
                    return <OrdenLlevarUbicacion
                        key={this.state.numeroOrden}
                        orden={orden}
                        posicion={this.state.posicionArrayOrdenes}
                        setPosicionArrayOrdenes={this.setPosicionArrayOrdenes}
                        numeroOrden={this.state.numeroOrden}
                        goBack={this.goBack}
                    />;
                case "006":
                    return <OrdenTerminarOrden
                        key={this.state.numeroOrden}
                        orden={orden}
                        posicion={this.state.posicionArrayOrdenes}
                        setPosicionArrayOrdenes={this.setPosicionArrayOrdenes}
                        numeroOrden={this.state.numeroOrden}
                        goBack={this.goBack}
                    />;
                default:
                    break;
            }
        }
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Orden
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={styles.viewContainer}>
                        <Text style={styles.viewText}>Procesar Órden</Text>
                    </View>
                    <Text>
                        Orden
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCode(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de orden"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.comprobarOrden(); }}
                            onChangeText={this.handleCodigoOrden}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.ordenInput = input}
                            selectTextOnFocus
                            value={this.state.codigoOrden}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoOrden: "", ordenCorrecta: true });
                                this.handleCodigoOrden("");
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>

                    {!this.state.ordenCorrecta ? <View
                        style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>Orden no encontrada o terminada</Text>
                        </View>
                    </View> : <View style={styles.logoContainer}>
                            <Image
                                style={styles.logo}
                                source={require('../assets/barcode_reader.gif')}
                                resizeMode="contain"
                            />
                        </View>}
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: navigation.getParam("title", 'Facturación Automática'),
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
            //headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

class OrdenPesarVacio extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            codigoPale: this.props.orden.PaleOri,
            paleCorrecto: true,
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.paleInput.focus();
        }, 550);
    }

    componentDidUpdate(prevProps) {
        if (this.props.posicion != prevProps.posicion) {
            this.setState({
                mostrarEscaner: false,
                spinner: false,
                codigoPale: this.props.orden.PaleOri,
                paleCorrecto: true
            });
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleCodigoPale = (codigoPale) => {
        this.setState({ codigoPale: codigoPale });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoPale: data }, () => {
            this.comprobarPale();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    comprobarPale = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        if (myThis.props.orden.PaleOri == myThis.state.codigoPale) {
            getPale(myThis.state.codigoPale, myThis.props.numeroOrden, function (respuesta) {
                if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1301") {
                    getTerminarLinea(myThis.props.numeroOrden, Number(myThis.props.posicion) + 1, function (respuesta) {
                        if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1101") {
                            myThis.setSpinnerVisible(false);
                            myThis.setState({
                                paleCorrecto: true,
                            }, () => {
                                myThis.props.setPosicionArrayOrdenes(Number(myThis.props.posicion) + 1);
                            });
                        } else {
                            myThis.setSpinnerVisible(false);
                            setTimeout(() => {
                                myThis.setError({
                                    title: 'Error',
                                    message: 'Error en la respuesta al terminar una linea ' + respuesta.Mensaje.Estado,
                                    visible: true,
                                    onTouchOutside: () => { },
                                    negativeButton: {
                                        title: 'Reintentar',
                                        onPress: () => { myThis.limpiarError(); myThis.comprobarPale(); }
                                    },
                                    positiveButton: {
                                        title: 'Cancelar',
                                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                    }
                                });
                            }, alertTimeout);
                        }
                    }, function (error) {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error',
                                message: 'Error en la comunicación con el servidor (-402)',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.comprobarPale(); }
                                },
                                positiveButton: {
                                    title: 'Cancelar',
                                    onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                }
                            });
                        }, alertTimeout);
                    });
                } else {
                    myThis.setSpinnerVisible(false);
                    myThis.setState({
                        paleCorrecto: false
                    }, () => {
                        setTimeout(() => {
                            myThis.paleInput.focus();
                        }, 550);
                    });
                }
            }, function (error) {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error en la comunicación con el servidor (-401)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.comprobarPale(); }
                        },
                        positiveButton: {
                            title: 'Cancelar',
                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                        }
                    });
                }, alertTimeout);
            });
        } else {
            myThis.setSpinnerVisible(false);
            myThis.setState({
                paleCorrecto: false
            }, () => {
                setTimeout(() => {
                    myThis.paleInput.focus();
                }, 550);
            });
        }
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Palé
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={[styles.viewContainer, { paddingVertical: 5 }]}>
                        <Text style={styles.viewText}>Pesar Palé Vacío</Text>
                    </View>
                    <Text style={{ width: "100%", textAlign: "center" }}>
                        Ponga el Palé en la balanza.
                    </Text>
                    <Text style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
                        Genere la etiqueta del Palé desde el PC de la balanza.
                    </Text>
                    <Text style={{ width: "100%", textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                        Obtenga el Código de Palé.
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCode(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de palé"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.comprobarPale(); }}
                            onChangeText={this.handleCodigoPale}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.paleInput = input}
                            selectTextOnFocus
                            value={this.state.codigoPale}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoPale: "", paleCorrecto: true });
                                this.handleCodigoPale("");
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>

                    {!this.state.paleCorrecto ? <View
                        style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>Pale incorrecto o no encontrado</Text>
                        </View>
                    </View> : <View></View>}

                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.comprobarPale(); }}>
                            <Text style={styles.buttonText}>
                                Confirmar
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

class OrdenPasarCajas extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: true,
            codigoArticulo: "",
            articuloCorrecto: true,
            obtenido_Articulo: "",
            obtenido_DescArt: "",
            obtenido_Ubica: "",
            obtenido_Cajas: "",
            obtenido_Adjudicado: "",
            obtenido_codigoBarras: [],
            obtenido_MoverCantidad: this.props.orden.Cantidad,
            obtenido_Pendiente: this.props.orden.Cantidad,
            bloqueado: true,
            showDialogAutorizacion: false,
            codigoAutorizacion: "",
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.getDatos();
    }

    componentDidUpdate(prevProps) {
        if (this.props.posicion != prevProps.posicion) {
            this.setState({
                mostrarEscaner: false,
                spinner: true,
                codigoArticulo: "",
                articuloCorrecto: true,
                obtenido_Articulo: "",
                obtenido_DescArt: "",
                obtenido_Ubica: "",
                obtenido_Cajas: "",
                obtenido_Adjudicado: "",
                obtenido_codigoBarras: [],
                obtenido_MoverCantidad: this.props.orden.Cantidad,
                obtenido_Pendiente: this.props.orden.Cantidad,
                bloqueado: true,
                showDialogAutorizacion: false,
                codigoAutorizacion: ""
            }, () => {
                this.getDatos();
            });
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleCodigoArticulo = (codigoArticulo) => {
        this.setState({ codigoArticulo: codigoArticulo });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoArticulo: data }, () => {
            this.comprobarArticulo();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    bajarCantidad = () => {
        if (!this.state.bloqueado) {
            if (this.state.obtenido_MoverCantidad > 0) {
                this.setState({
                    obtenido_MoverCantidad: Number(this.state.obtenido_MoverCantidad) - 1,
                    obtenido_Pendiente: Number(this.state.obtenido_Pendiente) - 1
                });
                //if (Number(this.state.obtenido_MoverCantidad) - 1 < Number(this.state.obtenido_Pendiente)) {
            }
        } else {
            this.showDialogAutorizacion(true);
        }
    }

    subirCantidad = () => {
        if (!this.state.bloqueado) {
            this.setState({
                obtenido_MoverCantidad: Number(this.state.obtenido_MoverCantidad) + 1,
                obtenido_Pendiente: Number(this.state.obtenido_Pendiente) + 1
            });
        } else {
            this.showDialogAutorizacion(true);
        }
    }

    handleCodigoAutorizacion = (codigoAutorizacion) => {
        this.setState({ codigoAutorizacion: codigoAutorizacion });
    }

    comprobarAutorizacion = () => {
        if (String(this.state.codigoAutorizacion) == "2773") {
            this.setState({ bloqueado: false, showDialogAutorizacion: false });
        } else {
            this.setState({ showDialogAutorizacion: false });
        }
    }

    showDialogAutorizacion = (show) => {
        this.setState({ showDialogAutorizacion: show });
    }

    codigoBarrasValido = (codigoBarras) => {
        for (let codigo of this.state.obtenido_codigoBarras) {
            if (String(codigo).replace(/\s/g, '') == codigoBarras) {
                return true;
            }
        }

        if (typeof this.state.obtenido_codigoBarras === 'string' || this.state.obtenido_codigoBarras instanceof String) {
            let codigo = this.state.obtenido_codigoBarras;
            if (String(codigo).replace(/\s/g, '') == codigoBarras) {
                return true;
            }
        }
        return false;
    }

    comprobarArticulo = () => {
        Keyboard.dismiss();
        const myThis = this;

        myThis.setSpinnerVisible(true);
        console.warn(myThis.state.obtenido_Pendiente);
        if (myThis.codigoBarrasValido(myThis.state.codigoArticulo) || myThis.state.obtenido_Pendiente == 0) {
            if (myThis.state.obtenido_Pendiente > 1) {
                let cantPendiente = Number(myThis.state.obtenido_Pendiente) - 1;
                let codPendiente = myThis.props.numeroOrden + "_" + myThis.state.obtenido_Articulo;
                myThis.setState({
                    codigoArticulo: "",
                    articuloCorrecto: true,
                    obtenido_Pendiente: cantPendiente
                }, async () => {
                    try {
                        await SecureStore.setItemAsync('CodPendiente', codPendiente);
                        console.warn("Guardado: " + codPendiente);
                    } catch (error) {
                        console.warn(error);
                    }
                    try {
                        await SecureStore.setItemAsync('CantPendiente', "" + cantPendiente);
                        console.warn("Guardado: " + cantPendiente);
                    } catch (error) {
                        console.warn(error);
                    }
                    myThis.setSpinnerVisible(false);
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                });
            } else {
                getSacarArticulosPale(myThis.props.orden.PaleOri, myThis.state.obtenido_Articulo,
                    myThis.state.obtenido_MoverCantidad, myThis.props.numeroOrden, Number(myThis.props.posicion) + 1,
                    function (respuesta) {
                        if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "0801") {
                            getMeterArticulosPale(myThis.props.orden.PaleDes, myThis.state.obtenido_Articulo,
                                myThis.state.obtenido_MoverCantidad, myThis.props.numeroOrden, Number(myThis.props.posicion) + 1,
                                function (respuesta) {
                                    if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "0901") {
                                        getTerminarLinea(myThis.props.numeroOrden, Number(myThis.props.posicion) + 1, function (respuesta) {
                                            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1101") {
                                                myThis.setSpinnerVisible(false);
                                                myThis.setState({
                                                    articuloCorrecto: true,
                                                }, () => {
                                                    myThis.props.setPosicionArrayOrdenes(Number(myThis.props.posicion) + 1);
                                                });
                                            } else {
                                                myThis.setSpinnerVisible(false);
                                                setTimeout(() => {
                                                    myThis.setError({
                                                        title: 'Error',
                                                        message: 'Error en la respuesta al terminar una linea ' + respuesta.Mensaje.Estado,
                                                        visible: true,
                                                        onTouchOutside: () => { },
                                                        negativeButton: {
                                                            title: 'Reintentar',
                                                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                                        },
                                                        positiveButton: {
                                                            title: 'Cancelar',
                                                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                                        }
                                                    });
                                                }, alertTimeout);
                                            }
                                        }, function (error) {
                                            myThis.setSpinnerVisible(false);
                                            setTimeout(() => {
                                                myThis.setError({
                                                    title: 'Error',
                                                    message: 'Error en la comunicación con el servidor (-405)',
                                                    visible: true,
                                                    onTouchOutside: () => { },
                                                    negativeButton: {
                                                        title: 'Reintentar',
                                                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                                    },
                                                    positiveButton: {
                                                        title: 'OK',
                                                        onPress: () => { myThis.limpiarError(); myThis.props.goBack() }
                                                    }
                                                });
                                            }, alertTimeout);
                                        });
                                    } else {
                                        myThis.setSpinnerVisible(false);
                                        setTimeout(() => {
                                            myThis.setError({
                                                title: 'Error',
                                                message: 'Error al meter artículos de un palé (-404)',
                                                visible: true,
                                                onTouchOutside: () => { },
                                                negativeButton: {
                                                    title: 'Reintentar',
                                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                                },
                                                positiveButton: {
                                                    title: 'Cancelar',
                                                    onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                                }
                                            });
                                        }, alertTimeout);
                                    }
                                }, function (error) {
                                    myThis.setSpinnerVisible(false);
                                    setTimeout(() => {
                                        myThis.setError({
                                            title: 'Error',
                                            message: 'Error en la comunicación con el servidor (-403)',
                                            visible: true,
                                            onTouchOutside: () => { },
                                            negativeButton: {
                                                title: 'Reintentar',
                                                onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                            },
                                            positiveButton: {
                                                title: 'Cancelar',
                                                onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                            }
                                        });
                                    }, alertTimeout);
                                });
                        } else {
                            myThis.setSpinnerVisible(false);
                            setTimeout(() => {
                                myThis.setError({
                                    title: 'Error',
                                    message: 'Error al sacar artículos de un palé (-402)',
                                    visible: true,
                                    onTouchOutside: () => { },
                                    negativeButton: {
                                        title: 'Reintentar',
                                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                    },
                                    positiveButton: {
                                        title: 'Cancelar',
                                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                    }
                                });
                            }, alertTimeout);
                        }
                    }, function (error) {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error',
                                message: 'Error en la comunicación con el servidor (-401)',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                },
                                positiveButton: {
                                    title: 'Cancelar',
                                    onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                }
                            });
                        }, alertTimeout);
                    });
            }
        } else {
            myThis.setSpinnerVisible(false);
            myThis.setState({
                articuloCorrecto: false
            }, () => {
                setTimeout(() => {
                    myThis.articuloInput.focus();
                }, 550);
            });
        }
    }

    getDatos = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getArticuloCajas(myThis.props.orden.PaleOri, function (respuesta) {
            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "0701") {
                myThis.setState({
                    obtenido_Articulo: respuesta.Mensaje.CodArt,
                    obtenido_DescArt: respuesta.Mensaje.DesArt,
                    obtenido_Cajas: respuesta.Mensaje.Cajas,
                    obtenido_Adjudicado: respuesta.Mensaje.Adjudicado,
                    obtenido_codigoBarras: respuesta.Mensaje.CodBarra
                }, () => {
                    getUbicacionCajas(respuesta.Mensaje.Ubica, function (respuestaUbica) {
                        if (respuestaUbica.Mensaje && respuestaUbica.Mensaje.Estado && respuestaUbica.Mensaje.Estado == "0601") {
                            myThis.setState({
                                obtenido_Ubica: respuestaUbica.Mensaje.DescUbica,
                            }, async () => {
                                let codigoAnterior = "";
                                let cantidadAnterior = "";

                                try {
                                    codigoAnterior = await SecureStore.getItemAsync('CodPendiente');
                                    console.warn("Leido codigo: " + codigoAnterior);
                                } catch (error) {
                                    console.warn(error);
                                }

                                if (codigoAnterior == myThis.props.numeroOrden + "_" + respuesta.Mensaje.CodArt) {
                                    try { cantidadAnterior = await SecureStore.getItemAsync('CantPendiente'); } catch (error) { }
                                    console.warn("Leido cantidad: " + cantidadAnterior);
                                }
                                if (cantidadAnterior && cantidadAnterior != "") {
                                    if (myThis.state.obtenido_MoverCantidad < Number(cantidadAnterior)) {
                                        cantidadAnterior = myThis.state.obtenido_MoverCantidad;
                                    }
                                    myThis.setState({
                                        obtenido_Pendiente: Number(cantidadAnterior),
                                    });
                                }

                                myThis.setSpinnerVisible(false);
                                setTimeout(() => {
                                    myThis.articuloInput.focus();
                                }, 550);
                            });
                        } else {
                            myThis.setSpinnerVisible(false);
                            setTimeout(() => {
                                myThis.setError({
                                    title: 'Error',
                                    message: 'Error obteniendo datos del articulo (-403)',
                                    visible: true,
                                    onTouchOutside: () => { },
                                    negativeButton: {
                                        title: 'Reintentar',
                                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                    },
                                    positiveButton: {
                                        title: 'Cancelar',
                                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                    }
                                });
                            }, alertTimeout);
                        }
                    }, function (error) {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error',
                                message: 'Error en la comunicación con el servidor (-404)',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                },
                                positiveButton: {
                                    title: 'Cancelar',
                                    onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                }
                            });
                        }, alertTimeout);
                    });
                });
            } else {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error obteniendo datos del articulo (-402)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                        },
                        positiveButton: {
                            title: 'Cancelar',
                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                    },
                    positiveButton: {
                        title: 'Cancelar',
                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                    }
                });
            }, alertTimeout);
        });
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Articulo
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={[styles.viewContainer, { paddingVertical: 5 }]}>
                        <Text style={styles.viewText}>Pasar Cajas Entre Palés</Text>
                    </View>
                    <Text style={{ width: "100%", textAlign: "left", fontWeight: "700", fontSize: 17 }}>
                        Sitúese en Ubicacion / Palé:
                    </Text>
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17 }}>{this.state.obtenido_Ubica}</Text>
                        </View>
                    </View>
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 5 }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17 }}>{
                                `Cajas (Stock): ` + this.state.obtenido_Cajas +
                                `\nAdjudicado: ` + this.state.obtenido_Adjudicado}
                            </Text>
                        </View>
                    </View>
                    {/*<View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 5 }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17 }}>{this.props.orden.PaleOri}</Text>
                        </View>
                    </View>*/}
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 5 }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17 }}>{this.state.obtenido_Articulo}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 5, alignSelf: "center" }}>
                        <TouchableOpacity
                            onPress={() => { this.bajarCantidad(); }}
                            style={{ alignSelf: 'center', marginLeft: 5, marginRight: 15 }}>
                            <Feather name={"minus-circle"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <Text style={{ textAlign: "left", fontWeight: "700", fontSize: 17, marginTop: 5 }}>
                            {`Cajas totales a facturar: ` + this.state.obtenido_MoverCantidad}
                        </Text>
                        <TouchableOpacity
                            onPress={() => { this.subirCantidad(); }}
                            style={{ alignSelf: 'center', marginRight: 5, marginLeft: 15 }}>
                            <Feather name={"plus-circle"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: "row", alignSelf: "center" }}>
                        <Text style={{ textAlign: "left", fontWeight: "700", fontSize: 17 }}>
                            {`Cajas pendientes de facturar: ` + this.state.obtenido_Pendiente}
                        </Text>
                    </View>
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17 }}>{this.state.obtenido_DescArt}</Text>
                        </View>
                    </View>
                    {this.state.obtenido_Pendiente > 0 ?
                        <View>
                            <Text style={{ width: "100%", textAlign: "left", fontWeight: "700", fontSize: 17, marginTop: 5 }}>
                                {`Escanee el código una vez por cada caja`}
                            </Text>
                            <View style={styles.inputRow}>
                                <TouchableOpacity
                                    onPress={() => { this.scanCode(); }}
                                    style={{ alignSelf: 'center', marginLeft: 5 }}>
                                    <Feather name={"camera"} size={30} color="#d24040" />
                                </TouchableOpacity>
                                <TextInput
                                    placeholder="Introduzca código de barras"
                                    placeholderTextColor="#d24040"
                                    returnKeyType="go"
                                    onSubmitEditing={() => { this.comprobarArticulo(); }}
                                    onChangeText={this.handleCodigoArticulo}
                                    style={styles.input}
                                    underlineColorAndroid={'transparent'}
                                    ref={(input) => this.articuloInput = input}
                                    selectTextOnFocus
                                    value={this.state.codigoArticulo}
                                />
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ codigoArticulo: "", articuloCorrecto: true });
                                        this.handleCodigoArticulo("");
                                    }}
                                    style={{ alignSelf: 'center', marginRight: 5 }}>
                                    <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                                </TouchableOpacity>
                            </View>
                            {!this.state.articuloCorrecto ? <View
                                style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                                <View style={[{ marginHorizontal: 15, }]}>
                                    <Text style={{ fontSize: 17, fontWeight: "700" }}>Articulo incorrecto</Text>
                                </View>
                            </View> : <View></View>}

                            <View style={{ height: "10%" }}>
                                <TouchableOpacity
                                    style={[styles.buttonContainer]}
                                    onPress={() => { this.comprobarArticulo(); }}>
                                    <Text style={styles.buttonText}>
                                        {this.state.obtenido_Pendiente > 1 ? 'Siguiente caja' : 'Última caja'}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View> : <View></View>}
                    {this.state.obtenido_Pendiente <= 0 ? <View>
                        <Text style={{ width: "100%", textAlign: "left", fontWeight: "700", fontSize: 17, marginTop: 5 }}>
                            {`No quedan cajas pendientes por facturar`}
                        </Text>
                        <View style={{ height: "10%" }}>
                            <TouchableOpacity
                                style={[styles.buttonContainer]}
                                onPressIn={() => { this.comprobarArticulo(); }}>
                                <Text style={styles.buttonText}>
                                    Confirmar
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View> : <View></View>}
                    <DialogInput
                        isDialogVisible={false}
                        title={"Autorización necesaria"}
                        message={"Para modificar la cantidad a mover es necesario un PIN"}
                        hintInput={"Codigo de autorización"}
                        cancelText={"Cancelar"}
                        submitText={"Confirmar"}
                        //textInputProps={{ keyboardType: "number-pad" }}
                        submitInput={(inputText) => { this.comprobarAutorizacion(inputText); }}
                        closeDialog={() => { this.showDialogAutorizacion(false); }}>
                    </DialogInput>
                    <Dialog.Container
                        visible={this.state.showDialogAutorizacion}>
                        <Dialog.Title>Autorización necesaria</Dialog.Title>
                        <Dialog.Description>
                            Para modificar la cantidad a mover es necesario un PIN.
                        </Dialog.Description>
                        <Dialog.Input
                            label="Codigo de autorización"
                            style={[styles.inputPassword]}
                            placeholder="Introduzca código de autorización"
                            placeholderTextColor="#d24040"
                            onSubmitEditing={() => { this.comprobarAutorizacion(); }}
                            underlineColorAndroid={'transparent'}
                            keyboardType={'number-pad'}
                            secureTextEntry
                            onChangeText={this.handleCodigoAutorizacion}>
                        </Dialog.Input>
                        <Dialog.Button
                            label="Cancelar"
                            color="#d24040"
                            onPress={() => { this.showDialogAutorizacion(false); }} />
                        <Dialog.Button
                            label="Confirmar"
                            color="#d24040"
                            bold={true}
                            onPress={() => { this.comprobarAutorizacion(); }} />
                    </Dialog.Container>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

class OrdenPesarLleno extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            codigoPale: this.props.orden.PaleOri,
            paleCorrecto: true,
            peso: 0,
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.paleInput.focus();
        }, 550);
    }

    componentDidUpdate(prevProps) {
        if (this.props.posicion != prevProps.posicion) {
            this.setState({
                mostrarEscaner: false,
                spinner: false,
                codigoPale: this.props.orden.PaleOri,
                paleCorrecto: true
            });
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleCodigoPale = (codigoPale) => {
        this.setState({ codigoPale: codigoPale });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoPale: data }, () => {
            this.pesarLleno();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    pesarLleno = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        if (myThis.props.orden.PaleOri == myThis.state.codigoPale) {
            getPesarLleno(myThis.state.codigoPale, myThis.props.numeroOrden, myThis.state.peso, function (respuesta) {
                if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1401") {
                    getTerminarLinea(myThis.props.numeroOrden, Number(myThis.props.posicion) + 1, function (respuesta) {
                        if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1101") {
                            myThis.setSpinnerVisible(false);
                            myThis.setState({
                                paleCorrecto: true,
                            }, () => {
                                myThis.props.setPosicionArrayOrdenes(Number(myThis.props.posicion) + 1);
                            });
                        } else {
                            myThis.setSpinnerVisible(false);
                            setTimeout(() => {
                                myThis.setError({
                                    title: 'Error',
                                    message: 'Error en la respuesta al terminar una linea ' + respuesta.Mensaje.Estado,
                                    visible: true,
                                    onTouchOutside: () => { },
                                    negativeButton: {
                                        title: 'Reintentar',
                                        onPress: () => { myThis.limpiarError(); myThis.pesarLleno(); }
                                    },
                                    positiveButton: {
                                        title: 'Cancelar',
                                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                    }
                                });
                            }, alertTimeout);
                        }
                    }, function (error) {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error',
                                message: 'Error en la comunicación con el servidor (-402)',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.pesarLleno(); }
                                },
                                positiveButton: {
                                    title: 'Cancelar',
                                    onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                }
                            });
                        }, alertTimeout);
                    });
                } else {
                    myThis.setSpinnerVisible(false);
                    myThis.setState({
                        paleCorrecto: false
                    }, () => {
                        setTimeout(() => {
                            myThis.paleInput.focus();
                        }, 550);
                    });
                }
            }, function (error) {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error en la comunicación con el servidor (-401)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.pesarLleno(); }
                        },
                        positiveButton: {
                            title: 'Cancelar',
                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                        }
                    });
                }, alertTimeout);
            });
        } else {
            myThis.setSpinnerVisible(false);
            myThis.setState({
                paleCorrecto: false
            }, () => {
                setTimeout(() => {
                    myThis.paleInput.focus();
                }, 550);
            });
        }
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Palé
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={[styles.viewContainer, { paddingVertical: 5 }]}>
                        <Text style={styles.viewText}>Pesar Palé Lleno</Text>
                    </View>
                    <Text style={{ width: "100%", textAlign: "center" }}>
                        Ponga el Palé en la balanza.
                    </Text>
                    <Text style={{ width: "100%", textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                        Obtenga el Código de Palé.
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCode(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de palé"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.pesarLleno(); }}
                            onChangeText={this.handleCodigoPale}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.paleInput = input}
                            selectTextOnFocus
                            value={this.state.codigoPale}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoPale: "", paleCorrecto: true });
                                this.handleCodigoPale("");
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>

                    {!this.state.paleCorrecto ? <View
                        style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>Pale incorrecto o no encontrado</Text>
                        </View>
                    </View> : <View></View>}

                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.pesarLleno(); }}>
                            <Text style={styles.buttonText}>
                                Confirmar
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

class OrdenLlevarUbicacion extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscanerPale: false,
            mostrarEscanerUbicacion: false,
            spinner: false,
            codigoPale: this.props.orden.PaleOri,
            codigoUbicacion: "",
            paleCorrecto: true,
            ubicacionCorrecta: true,
            obtenido_Ubica: "",
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.getDatos();
    }

    componentDidUpdate(prevProps) {
        if (this.props.posicion != prevProps.posicion) {
            this.setState({
                mostrarEscanerPale: false,
                mostrarEscanerUbicacion: false,
                spinner: false,
                codigoPale: this.props.orden.PaleOri,
                codigoUbicacion: "",
                paleCorrecto: true,
                ubicacionCorrecta: true,
                obtenido_Ubica: "",
            }, () => {
                this.getDatos();
            });
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleCodigoPale = (codigoPale) => {
        this.setState({ codigoPale: codigoPale });
    }

    handleCodigoUbicacion = (codigoUbicacion) => {
        this.setState({ codigoUbicacion: codigoUbicacion });
    }

    scanCodePale = () => {
        this.setState({ mostrarEscanerPale: true });
    }

    scanCodeUbicacion = () => {
        this.setState({ mostrarEscanerUbicacion: true });
    }

    handleBarCodeScannedPale = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscanerPale: false, codigoPale: data }, () => {
            this.comprobarPale();
        });
    }

    handleBarCodeScannedUbicacion = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscanerUbicacion: false, codigoUbicacion: data }, () => {
            this.llevarPaleUbi();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    comprobarPale = () => {
        Keyboard.dismiss();
        if (this.props.orden.PaleOri == this.state.codigoPale) {
            this.setState({
                paleCorrecto: true,
            }, () => {
                setTimeout(() => {
                    this.ubicacionInput.focus();
                }, 550);
            });
        } else {
            this.setState({
                paleCorrecto: false
            }, () => {
                setTimeout(() => {
                    this.paleInput.focus();
                }, 550);
            });
        }
    }

    llevarPaleUbi = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        if (myThis.props.orden.PaleOri == myThis.state.codigoPale) {
            getCompararUbicaciones(myThis.props.orden.UbicaDes, myThis.state.codigoUbicacion, function (respuesta) {
                if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1501") {
                    getIntroPaleUbi(myThis.props.orden.PaleOri, myThis.state.codigoUbicacion, function (respuesta) {
                        if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "0201") {
                            getTerminarLinea(myThis.props.numeroOrden, Number(myThis.props.posicion) + 1, function (respuesta) {
                                if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1101") {
                                    myThis.setSpinnerVisible(false);
                                    myThis.setState({
                                        paleCorrecto: true,
                                    }, () => {
                                        myThis.props.setPosicionArrayOrdenes(Number(myThis.props.posicion) + 1);
                                    });
                                } else {
                                    myThis.setSpinnerVisible(false);
                                    setTimeout(() => {
                                        myThis.setError({
                                            title: 'Error',
                                            message: 'Error en la respuesta al terminar una linea ' + respuesta.Mensaje.Estado,
                                            visible: true,
                                            onTouchOutside: () => { },
                                            negativeButton: {
                                                title: 'Reintentar',
                                                onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                            },
                                            positiveButton: {
                                                title: 'Cancelar',
                                                onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                            }
                                        });
                                    }, alertTimeout);
                                }
                            }, function (error) {
                                myThis.setSpinnerVisible(false);
                                setTimeout(() => {
                                    myThis.setError({
                                        title: 'Error',
                                        message: 'Error en la comunicación con el servidor (-404)',
                                        visible: true,
                                        onTouchOutside: () => { },
                                        negativeButton: {
                                            title: 'Reintentar',
                                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                        },
                                        positiveButton: {
                                            title: 'Cancelar',
                                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                        }
                                    });
                                }, alertTimeout);
                            });
                        } else {
                            myThis.setSpinnerVisible(false);
                            setTimeout(() => {
                                myThis.setError({
                                    title: 'Error',
                                    message: 'Error al introducir pale en ubicacion ' + respuesta.Mensaje.Estado,
                                    visible: true,
                                    onTouchOutside: () => { },
                                    negativeButton: {
                                        title: 'Reintentar',
                                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                    },
                                    positiveButton: {
                                        title: 'Cancelar',
                                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                    }
                                });
                            }, alertTimeout);
                        }
                    }, function (error) {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error',
                                message: 'Error en la comunicación con el servidor (-402)',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                                },
                                positiveButton: {
                                    title: 'Cancelar',
                                    onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                                }
                            });
                        }, alertTimeout);
                    });
                } else {
                    myThis.setSpinnerVisible(false);
                    myThis.setState({
                        ubicacionCorrecta: false
                    }, () => {
                        setTimeout(() => {
                            myThis.ubicacionInput.focus();
                        }, 550);
                    });
                }
            }, function (error) {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error en la comunicación con el servidor (-401)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                        },
                        positiveButton: {
                            title: 'Cancelar',
                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                        }
                    });
                }, alertTimeout);
            });
        } else {
            myThis.setSpinnerVisible(false);
            myThis.setState({
                paleCorrecto: false
            }, () => {
                setTimeout(() => {
                    myThis.paleInput.focus();
                }, 550);
            });
        }
    }

    getDatos = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);

        getUbicacionCajas(myThis.props.orden.UbicaDes, function (respuestaUbica) {
            if (respuestaUbica.Mensaje && respuestaUbica.Mensaje.Estado && respuestaUbica.Mensaje.Estado == "0601") {
                myThis.setState({
                    obtenido_Ubica: respuestaUbica.Mensaje.DescUbica,
                }, () => {
                    myThis.setSpinnerVisible(false);
                    setTimeout(() => {
                        myThis.ubicacionInput.focus();
                    }, 550);
                });
            } else {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error obteniendo datos del articulo (-403)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                        },
                        positiveButton: {
                            title: 'Cancelar',
                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-404)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getDatos(); }
                    },
                    positiveButton: {
                        title: 'Cancelar',
                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                    }
                });
            }, alertTimeout);
        });
    }

    render() {
        if (this.state.mostrarEscanerPale) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Palé
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscanerPale ? this.handleBarCodeScannedPale : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscanerPale: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        } else if (this.state.mostrarEscanerUbicacion) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Ubicación
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscanerUbicacion ? this.handleBarCodeScannedUbicacion : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscanerUbicacion: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={[styles.viewContainer, { paddingVertical: 5 }]}>
                        <Text style={styles.viewText}>Llevar Palé a Ubicación</Text>
                    </View>
                    <Text style={{ width: "100%", textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                        Obtenga el Código de Palé.
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCodePale(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de palé"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.comprobarPale(); }}
                            onChangeText={this.handleCodigoPale}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.paleInput = input}
                            selectTextOnFocus
                            value={this.state.codigoPale}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoPale: "", paleCorrecto: true });
                                this.handleCodigoPale("");
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>

                    {!this.state.paleCorrecto ? <View
                        style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>Pale incorrecto o no encontrado</Text>
                        </View>
                    </View> : <View></View>}

                    <Text style={{ width: "100%", textAlign: "left", fontWeight: "700", fontSize: 17 }}>
                        Diríjase a la ubicación Destino:
                    </Text>
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17 }}>{this.state.obtenido_Ubica}</Text>
                        </View>
                    </View>

                    <Text style={{ width: "100%", textAlign: "left", fontWeight: "700", fontSize: 17 }}>
                        Coloque palé y obtenga Ubicación:
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCodeUbicacion(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de ubicación"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.llevarPaleUbi(); }}
                            onChangeText={this.handleCodigoUbicacion}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.ubicacionInput = input}
                            selectTextOnFocus
                            value={this.state.codigoUbicacion}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoUbicacion: "", ubicacionCorrecta: true });
                                this.handleCodigoUbicacion("");
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>

                    {!this.state.ubicacionCorrecta ? <View
                        style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>Ubicación incorrecta o no encontrada</Text>
                        </View>
                    </View> : <View></View>}


                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.llevarPaleUbi(); }}>
                            <Text style={styles.buttonText}>
                                Confirmar
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

class OrdenTerminarOrden extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            spinner: true,
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.terminarOrden();
    }

    componentDidUpdate(prevProps) {
        if (this.props.posicion != prevProps.posicion) {
            this.setState({
                spinner: true,
            }, () => {
                this.terminarOrden();
            });
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    terminarOrden = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getTerminarOrden(myThis.props.numeroOrden, function (respuesta) {
            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "1201") {
                myThis.setSpinnerVisible(false);
                myThis.props.setPosicionArrayOrdenes(Number(myThis.props.posicion) + 1);
            } else {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error terminando orden: ' + respuesta.Mensaje.Estado,
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.terminarOrden(); }
                        },
                        positiveButton: {
                            title: 'Cancelar',
                            onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.terminarOrden(); }
                    },
                    positiveButton: {
                        title: 'Cancelar',
                        onPress: () => { myThis.limpiarError(); myThis.props.goBack(); }
                    }
                });
            }, alertTimeout);
        });
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={[styles.viewContainer, { paddingVertical: 5 }]}>
                        <Text style={styles.viewText}>Terminando orden</Text>
                    </View>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Terminando orden..."} textStyle={{ color: '#FFF' }} />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 25
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    },
    logoContainer: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        maxHeight: '40%'
    },
    logo: {
        maxWidth: '80%',
        flex: 1
    },
    inputPassword: {
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        color: "#d24040"
    }
});

export default withNavigationFocus(ProcesarOrdenes);