import React, { Component } from "react";
import {
    Text,
    View,
    StyleSheet,
    Image,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    Platform,
    Linking,
    Share
} from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import Toast from "react-native-root-toast";
import SettingsList from "react-native-settings-list";
import { Notifications, AppLoading } from "expo";
import Constants from "expo-constants";
import { Asset } from "expo-asset";
import * as Permissions from "expo-permissions";
import * as Localization from "expo-localization";
import * as Font from "expo-font";
import * as SecureStore from 'expo-secure-store';
import * as Network from 'expo-network';
import * as Device from 'expo-device';
import * as Crypto from 'expo-crypto'
import * as IntentLauncher from 'expo-intent-launcher';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as Updates from 'expo-updates';
import { MaterialIcons, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { getLogin, alertTimeout, checkUpdates, checkCamera, sendWebhook, ModalLarry } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';
const pass = "975F84C964EBE8FD1117A6B9D5614952FB5CD357CEB081533533E536C1622C63";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAppReady: false,
            updating: false,
            settings: false,
            pushToken: '',
            spinner: false,
            dialogToken: false,
            informacionSistema: "",
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    async _cacheResourcesAsync() {
        const images = [
            require('../assets/logo_akra.png'),
            require('../assets/id.png'),
            require('../assets/information.png'),
            require('../assets/login.png'),
            require('../assets/notifications.png'),
            require('../assets/update.png')
        ];

        const fonts = [
            Feather.font,
            MaterialCommunityIcons.font,
            MaterialIcons.font,
        ];

        const cacheImages = images.map((image) => {
            return Asset.fromModule(image).downloadAsync();
        });

        const cacheFonts = fonts.map((font) => {
            return Font.loadAsync(font);
        });

        await Promise.all([...cacheImages, ...cacheFonts]);
    }

    async componentDidMount() {
        this.props.navigation.setParams({
            headerRight: () => (
                <TouchableOpacity
                    style={{ paddingRight: 18 }}
                    onPress={() => { this.showSettings(true) }} >
                    <MaterialIcons name={"settings"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        if (Platform.OS != "web") {
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            this.registerForPushNotificationsAsync();
        }
        checkUpdates();
        checkCamera();
        this.setState({ informacionSistema: await this.getDatos() });
    }

    registerForPushNotificationsAsync = async () => {
        const { status: existingStatus } = await Permissions.getAsync(
            Permissions.NOTIFICATIONS
        );
        let finalStatus = existingStatus;

        // only ask if permissions have not already been determined, because
        // iOS won't necessarily prompt the user a second time.
        if (existingStatus !== 'granted') {
            // Android remote notification permissions are granted during the app
            // install, so this will only ask on iOS
            const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
            finalStatus = status;
        }

        // Stop here if the user did not grant permissions
        if (finalStatus !== 'granted') {
            Toast.show('Sin permisos no se pueden mostrar notificaciones!', {
                duration: Toast.durations.LONG,
                position: Toast.positions.BOTTOM,
                shadow: true,
                animation: true,
                hideOnPress: true,
                delay: 0,
                onShow: () => { },
                onShown: () => { },
                onHide: () => { },
                onHidden: () => { }
            });
            return
        } else {
            // Get the token that uniquely identifies this device
            const pushToken = await Notifications.getExpoPushTokenAsync();
            this.setState({ pushToken: pushToken });
            try {
                await SecureStore.setItemAsync('pushToken', pushToken);
            } catch (error) { }

            if (Platform.OS === 'android') {
                Notifications.createChannelAndroidAsync('gestoralmacen', {
                    name: 'gestoralmacen',
                    sound: true,
                    vibrate: true,
                    badge: true,
                });
                let pda;
                try { pda = 'Número de PDA: ' + await SecureStore.getItemAsync('PDA'); } catch (error) { };
                if (pda) {
                    Notifications.dismissAllNotificationsAsync();
                    Notifications.presentLocalNotificationAsync({
                        title: "Datos",
                        body: pda,
                        android: {
                            sticky: true
                        }
                    });
                }
            }
        }
    }

    updateApp = () => {
        this.setState({ updating: true }, () => {
            setTimeout(async () => {
                if (Platform.OS != "web") {
                    try {
                        const update = await Updates.checkForUpdateAsync();
                        if (update.isAvailable) {
                            await Updates.fetchUpdateAsync();
                            Updates.reloadAsync();
                        } else {
                            Toast.show('No hay actualizaciones disponibles, ya tienes la última versión!', {
                                duration: Toast.durations.LONG,
                                position: Toast.positions.BOTTOM,
                                shadow: true,
                                animation: true,
                                hideOnPress: true,
                                delay: 0,
                                onShow: () => { },
                                onShown: () => { },
                                onHide: () => { },
                                onHidden: () => { }
                            });
                        }
                    } catch (error) {
                        Toast.show('Ha ocurrido un error buscando actualizaciones, inténtelo de nuevo', {
                            duration: Toast.durations.LONG,
                            position: Toast.positions.BOTTOM,
                            shadow: true,
                            animation: true,
                            hideOnPress: true,
                            delay: 0,
                            onShow: () => { },
                            onShown: () => { },
                            onHide: () => { },
                            onHidden: () => { }
                        });
                    }
                } else {
                    Toast.show('GestorAlmacén versión WEB ya está en su última versión', {
                        duration: Toast.durations.LONG,
                        position: Toast.positions.BOTTOM,
                        shadow: true,
                        animation: true,
                        hideOnPress: true,
                        delay: 0,
                        onShow: () => { },
                        onShown: () => { },
                        onHide: () => { },
                        onHidden: () => { }
                    });
                }
                this.setState({ updating: false });
            }, 1000);
        });
    }

    showSettings = (visible) => {
        this.setState({
            settings: visible
        });
    }

    showNotificationsSettings = () => {
        if (Platform.OS === 'android') {
            IntentLauncher.startActivityAsync(
                //IntentLauncher.ACTION_APP_NOTIFICATION_SETTINGS
                IntentLauncher.ACTION_APPLICATION_SETTINGS,
                { package: 'com.akratecnologia.gestoralmacen' }
            );
        } else {
            Linking.openURL('app-settings:');
        }
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    getDatos = async () => {
        let ip;
        try { ip = '\nIp: ' + await Network.getIpAddressAsync(); } catch (error) { };
        let pda;
        try { pda = '\nPDA: ' + await SecureStore.getItemAsync('PDA'); } catch (error) { };
        if (Platform.OS == "web") {
            pda = "\nPDA: web (10)";
        }
        let version = Platform.Version;
        if (!isNaN(version)) {
            version = parseInt(Platform.Version, 10)
        }
        const año = Constants.deviceYearClass;

        return String('Expo: ' + Constants.expoVersion +
            '\nId: ' + Constants.installationId +
            '\nNombre: ' + Constants.deviceName +
            (año ? '\nAño: ' + año : '') +
            '\nPlataforma: ' + Platform.OS +
            (version ? '\nVersión: ' + version : '') +
            (Platform.OS === 'ios' ? '\nModelo: ' + Constants.platform.ios.model : '') +
            (Platform.OS === 'ios' ? '\nPaís: ' + await Localization.region : '') +
            '\nRoot: ' + await Device.isRootedExperimentalAsync() +
            (ip ? ip : '') +
            (pda ? pda : '') +
            '\nGestorAlmacen Versión: ' + Constants.manifest.version);
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    render() {
        if (!this.state.isAppReady) {
            return (
                <AppLoading
                    startAsync={this._cacheResourcesAsync}
                    onFinish={() => this.setState({ isAppReady: true })}
                    onError={console.warn}
                />
            );
        }

        const marginTop = (Platform.OS === 'android' || Platform.OS === 'web') ? 5 : 30;
        return (
            <View style={styles.container}>
                <View style={styles.logoContainer}>
                    <Image
                        style={styles.logo}
                        source={require('../assets/logo_akra.png')}
                        resizeMode="contain"
                    />
                </View>
                <View>
                    {/*<Text style={styles.title}>Autenticación</Text>*/}
                    <LoginForm
                        navigation={this.props.navigation}
                        setSpinnerVisible={this.setSpinnerVisible}
                        setError={this.setError}
                        limpiarError={this.limpiarError}
                    />
                </View>
                <ModalLarry
                    animationType="fade"
                    transparent={false}
                    visible={this.state.settings}
                    onRequestClose={() => { this.showSettings(false); }}>
                    <View style={{ backgroundColor: '#EFEFF4', flex: 1 }}>
                        <View style={{ borderBottomWidth: 1, backgroundColor: '#ff6161', borderColor: '#c8c7cc' }}>
                            <View style={{ flexDirection: 'row', marginTop: marginTop, marginBottom: 10 }}>
                                <TouchableOpacity
                                    style={{ zIndex: 999 }}
                                    onPress={() => { this.showSettings(false); }}>
                                    <MaterialIcons name={"chevron-left"} size={40} color="#fff" />
                                </TouchableOpacity>
                                <View style={{ position: 'absolute', width: '100%' }}>
                                    <Text style={{ marginTop: 8, fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: '#fff' }}>Ajustes</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ backgroundColor: '#EFEFF4', flex: 1 }}>
                            <SettingsList borderColor='#c8c7cc' defaultItemSize={50}>
                                <SettingsList.Header headerStyle={{ marginTop: 15 }} />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../assets/update.png')} />}
                                    title='Actualizar aplicación'
                                    titleInfo='Actualizar'
                                    titleInfoStyle={styles.titleInfoStyle}
                                    onPress={() => { this.showSettings(false); this.updateApp(); }}
                                />
                                {/*<SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../assets/login.png')} />}
                                    hasSwitch={true}
                                    switchState={this.state.autoLogin}
                                    switchOnValueChange={this.setAutoLogin}
                                    hasNavArrow={false}
                                    title='Inicio de sesión automático'
                                    onPress={() => { this.setAutoLogin(!this.state.autoLogin); }}
                                />*/}
                                {Platform.OS != "web" ? <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../assets/notifications.png')} />}
                                    title='Notificaciones'
                                    titleInfo='Abrir ajustes'
                                    titleInfoStyle={styles.titleInfoStyle}
                                    onPress={() => { this.showNotificationsSettings(); }}
                                /> : ""}
                                <SettingsList.Header headerStyle={{ marginTop: 15 }} />
                                {Platform.OS != "web" ? <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../assets/id.png')} />}
                                    title='Notificación push ID'
                                    onPress={() => {
                                        if (this.state.pushToken) {
                                            this.setState({ dialogToken: true });
                                        }
                                    }}
                                /> : ""}
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../assets/information.png')} />}
                                    title='Información del sistema'
                                    onPress={() => {
                                        this.setState({ dialogDatos: true });
                                    }}
                                />
                            </SettingsList>
                        </View>
                    </View>
                </ModalLarry>
                <ConfirmDialog
                    title="Notificación ID"
                    message={'Token: ' + this.state.pushToken + '\n'}
                    visible={this.state.dialogToken}
                    onTouchOutside={() => this.setState({ dialogToken: false })}
                    negativeButton={{
                        title: "Compartir",
                        onPress: () => {
                            if (Platform.OS != "web") {
                                Share.share({
                                    title: "Token",
                                    message: 'Token: ' + this.state.pushToken + '\n'
                                });
                            }
                        }
                    }}
                    positiveButton={{
                        title: "OK",
                        onPress: () => { this.setState({ dialogToken: false }) }
                    }}
                />
                <ConfirmDialog
                    title="Información del sistema"
                    message={this.state.informacionSistema}
                    visible={this.state.dialogDatos}
                    onTouchOutside={() => this.setState({ dialogDatos: false })}
                    negativeButton={{
                        title: "Compartir",
                        onPress: () => {
                            if (Platform.OS != "web") {
                                Share.share({
                                    title: "Información del telefono",
                                    message: this.state.informacionSistema
                                });
                            }
                        }
                    }}
                    positiveButton={{
                        title: "OK",
                        onPress: () => { this.setState({ dialogDatos: false }) }
                    }}
                />
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <ModalLarry
                    animationType="fade"
                    transparent={false}
                    visible={this.state.updating}
                    onRequestClose={() => { }}>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 20, fontWeight: '200', color: '#d24040', marginBottom: 20 }}>
                            Actualizando...
                        </Text>
                        <ActivityIndicator size="large" color="#d24040" />
                    </View>
                </ModalLarry>
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        )
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderRight = () => (
            <TouchableOpacity style={{ paddingRight: 18 }}>
                <MaterialIcons name={"settings"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            headerTitle: 'Gestor Almacen',
            headerBackTitle: 'Login',
            headerTruncatedBackTitle: 'Login',
            headerRight: navigation.getParam("headerRight", auxHeaderRight),
            headerStyle: {
                backgroundColor: '#FF6161',
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: 'white',
            }
        };
    }
}

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            enabled: true,
            numPda: -1,
        };
    }

    componentDidMount() {
        this.getPDA();
    }

    getPDA = async () => {
        let pda;
        try { pda = await SecureStore.getItemAsync('PDA'); } catch (error) { };
        if (Platform.OS == "web") {
            pda = "web (10)";
        }
        if (pda) {
            this.setState({
                numPda: pda
            });
        }
    }

    setSpinnerVisible = (visible) => {
        this.props.setSpinnerVisible(visible);
    }

    setCredentials = async (user, password) => {
        try {
            await SecureStore.setItemAsync('user', user);
        } catch (error) { }
        try {
            await SecureStore.setItemAsync('pass', password);
        } catch (error) { }
    }

    comprobarLogin = async () => {
        const { navigate } = this.props.navigation;
        const user = this.state.user;
        const password = this.state.password;
        const myThis = this;
        if (user && user != "" && password && password != "") {
            myThis.setSpinnerVisible(true);
            const password256 = await Crypto.digestStringAsync(
                Crypto.CryptoDigestAlgorithm.SHA256, password
            );
            if (user == "config" && password256.trim().toLowerCase() == pass.trim().toLowerCase()) {
                sendWebhook(":bangbang: Iniciando sesión para **CONFIGURAR PDA** :bangbang:");
                myThis.setSpinnerVisible(false);
                myThis.setState({
                    user: "",
                    password: ""
                });
                navigate('ConfigurarPDA', {
                });
            } else {
                getLogin(user, password, function (respuesta) {
                    myThis.setCredentials(user, password);
                    myThis.setSpinnerVisible(false);
                    if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "0001") {
                        myThis.setState({
                            user: "",
                            password: ""
                        });
                        navigate('MenuPrincipal', {
                        });
                    } else if (respuesta.Mensaje && respuesta.Mensaje.Estado
                        && (respuesta.Mensaje.Estado == "0002" || respuesta.Mensaje.Estado == "0003")) {
                        myThis.setState({
                            password: ""
                        });
                        setTimeout(() => {
                            myThis.props.setError({
                                title: "Error",
                                message: "Credenciales incorrectas",
                                visible: true,
                                onTouchOutside: () => { },
                                positiveButton: {
                                    title: "OK",
                                    onPress: () => { myThis.props.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    } else if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "NOCONFIG") {
                        myThis.setState({
                            password: ""
                        });
                        setTimeout(() => {
                            myThis.props.setError({
                                title: "Error",
                                message: "PDA NO CONFIGURADA, contacte con AKRA-TI",
                                visible: true,
                                onTouchOutside: () => { },
                                positiveButton: {
                                    title: "OK",
                                    onPress: () => { myThis.props.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    } else if (respuesta.Mensaje && respuesta.Mensaje.Estado) {
                        myThis.setState({
                            password: ""
                        });
                        setTimeout(() => {
                            myThis.props.setError({
                                title: "Error",
                                message: 'Error al hacer login (' + respuesta.Mensaje.Estado + ')',
                                visible: true,
                                onTouchOutside: () => { },
                                positiveButton: {
                                    title: "OK",
                                    onPress: () => { myThis.props.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    } else {
                        setTimeout(() => {
                            myThis.props.setError({
                                title: "Error",
                                message: "Error en la comunicación con el servidor (-101)",
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: "Reintentar",
                                    onPress: () => { myThis.props.limpiarError(); myThis.comprobarLogin(); }
                                },
                                positiveButton: {
                                    title: "OK",
                                    onPress: () => { myThis.props.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    }
                }, function (error) {
                    myThis.setSpinnerVisible(false);
                    setTimeout(() => {
                        myThis.props.setError({
                            title: "Error",
                            message: "Error en la comunicación con el servidor (-102)",
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: "Reintentar",
                                onPress: () => { myThis.props.limpiarError(); myThis.comprobarLogin(); }
                            },
                            positiveButton: {
                                title: "OK",
                                onPress: () => { myThis.props.limpiarError(); myThis.setState({ password: "" }); }
                            }
                        });
                    }, alertTimeout);
                });
            }
        } else {
            setTimeout(() => {
                myThis.props.setError({
                    title: "Error",
                    message: "Introduzca un usuario y contraseña válidos",
                    visible: true,
                    onTouchOutside: () => { },
                    positiveButton: {
                        title: "OK",
                        onPress: () => { myThis.props.limpiarError(); }
                    }
                });
            }, alertTimeout);
        }
    }

    handleUser = (user) => {
        this.setState({ user: user });
    }

    handlePassword = (password) => {
        this.setState({ password: password });
    }

    render() {
        return (
            <View style={styles.containerForm}>
                <Text style={{ paddingBottom: 5, color: "#d24040" }}>
                    PDA: {this.state.numPda}
                </Text>
                <View style={styles.inputRow}>
                    <Feather name={"user"} size={25} color="#d24040" style={{ alignSelf: 'center', marginLeft: 5 }} />
                    <TextInput
                        placeholder="Introduzca su usuario"
                        placeholderTextColor="#d24040"
                        returnKeyType="next"
                        onSubmitEditing={() => { this.passwordInput.focus(); }}
                        autoCapitalize="none"
                        autoCorrect={false}
                        onChangeText={this.handleUser}
                        style={styles.input}
                        underlineColorAndroid={'transparent'}
                        editable={this.state.enabled}
                        value={this.state.user}
                    />
                </View>
                <View style={styles.inputRow}>
                    <Feather name={"lock"} size={25} color="#d24040" style={{ alignSelf: 'center', marginLeft: 5 }} />
                    <TextInput
                        placeholder="Introduzca su contraseña"
                        placeholderTextColor="#d24040"
                        secureTextEntry
                        returnKeyType="go"
                        onSubmitEditing={() => { this.comprobarLogin(); }}
                        onChangeText={this.handlePassword}
                        style={styles.input}
                        ref={(input) => this.passwordInput = input}
                        underlineColorAndroid={'transparent'}
                        editable={this.state.enabled}
                        value={this.state.password}
                    />
                </View>
                <TouchableOpacity
                    disabled={!this.state.enabled}
                    style={styles.buttonContainer}
                    onPress={() => { this.comprobarLogin(); }}>
                    <Text style={styles.buttonText}>
                        Iniciar Sesión
                    </Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    logoContainer: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        maxHeight: '40%'
    },
    title: {
        color: '#d24040',
        marginTop: 10,
        opacity: 0.9,
        textAlign: 'center'
    },
    containerForm: {
        padding: 20,
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    },
    buttonContainer: {
        backgroundColor: '#ff6161',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#ff6161',
        marginTop: 20
    },
    buttonText: {
        textAlign: 'center',
        color: '#fff',
        fontWeight: '700',
        fontSize: 20
    },
    logo: {
        maxWidth: '80%',
        width: '100%',
        flex: 1
    },
    imageStyle: {
        marginLeft: 15,
        alignSelf: 'center',
        height: 30,
        width: 30
    },
    titleInfoStyle: {
        fontSize: 16,
        color: '#8e8e93'
    }
});
