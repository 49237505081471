import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    TextInput,
    Keyboard,
    BackHandler,
    Vibration,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import Spinner from "react-native-loading-spinner-overlay";
import { alertTimeout, getTramites, getUnicoCliente, crearVenta } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class NuevaVenta extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            codigoCliente: "",
            codigoInternoCliente: "",
            nombreCliente: "",
            arrayTramites: [],
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        setTimeout(() => {
            this.clienteInput.focus();
        }, 550);
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleBackPress = () => {
        if (this.state.mostrarEscaner) {
            this.setState({ mostrarEscaner: false });
            return true;
        } else {
            return false;
        }
    }

    goBack = () => {
        if (!this.handleBackPress()) {
            this.props.navigation.goBack();
        }
    }

    handleCodigoCliente = (codigoCliente) => {
        this.setState({ codigoCliente: codigoCliente });
    }

    getCodigoCliente = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getUnicoCliente(myThis.state.codigoCliente, function (respuestaCliente) {
            if (respuestaCliente && respuestaCliente.ClientesPDA && respuestaCliente.ClientesPDA.ClientePDA
                && respuestaCliente.ClientesPDA.ClientePDA.kc_cliente) {
                let nombre = 'CLIENTE SIN ALIAS NI NOMBRE DEFINIDOS';
                if (respuestaCliente.ClientesPDA.ClientePDA.nombre) {
                    nombre = respuestaCliente.ClientesPDA.ClientePDA.nombre;
                } else if (respuestaCliente.ClientesPDA.ClientePDA.alias) {
                    nombre = respuestaCliente.ClientesPDA.ClientePDA.alias;
                }
                myThis.setState({
                    codigoInternoCliente: respuestaCliente.ClientesPDA.ClientePDA.k_cliente,
                    nombreCliente: nombre.trim()
                }, () => {
                    getTramites(function (respuesta) {
                        myThis.setSpinnerVisible(false);
                        if (respuesta.TramitesPDA && respuesta.TramitesPDA.TramitePDA) {
                            let arrayTramites = [];
                            if (Array.isArray(respuesta.TramitesPDA.TramitePDA)) {
                                //arrayTramites = JSON.parse(JSON.stringify(respuesta.TramitesPDA.TramitePDA).replace(/\s/g, ''));
                                arrayTramites = respuesta.TramitesPDA.TramitePDA;
                            }
                            myThis.setState({
                                arrayTramites: arrayTramites
                            }, () => {
                                /*setTimeout(() => {
                                    myThis.clienteInput.focus();
                                }, 550);*/
                            });
                        } else {
                            myThis.setState({
                                nombreCliente: "EL USUARIO DE ESTA PDA NO TIENE TRÁMITES DISPONIBLES",
                                arrayTramites: []
                            }, () => {
                                /*setTimeout(() => {
                                    myThis.clienteInput.focus();
                                }, 550);*/
                            });
                        }
                    }, function (error) {
                        myThis.setSpinnerVisible(false);
                        setTimeout(() => {
                            myThis.setError({
                                title: 'Error',
                                message: 'Error en la comunicación con el servidor (-401)',
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: 'Reintentar',
                                    onPress: () => { myThis.limpiarError(); myThis.getCodigoCliente(); }
                                },
                                positiveButton: {
                                    title: 'OK',
                                    onPress: () => { myThis.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    });
                })
            } else {
                let error = "ERROR AL BUSCAR CLIENTES";
                if (respuestaCliente && respuestaCliente.ClientesPDA == null) {
                    error = "CLIENTE NO ENCONTRADO"
                }
                myThis.setSpinnerVisible(false);
                myThis.setState({
                    nombreCliente: error,
                    codigoInternoCliente: "",
                    arrayTramites: []
                }, () => {
                    setTimeout(() => {
                        myThis.clienteInput.focus();
                    }, 550);
                });
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-402)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.getCodigoCliente(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoCliente: data }, () => {
            this.getCodigoCliente();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    pushTramite = (tramite) => {
        const { navigate } = this.props.navigation;
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        crearVenta(myThis.state.codigoInternoCliente, tramite.k_tramite, function (respuesta) {
            if (respuesta && respuesta.PedidosPDA && respuesta.PedidosPDA.PedidoPDA) {
                myThis.setSpinnerVisible(false);
                navigate('ProcesarVenta', {
                    venta: respuesta.PedidosPDA.PedidoPDA
                });
            } else if (respuesta && respuesta.API_Status && respuesta.API_Status.Request_Status
                && respuesta.API_Status.Request_Status.toLowerCase() == "pedido ya existe.") {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Pedido ya existente',
                        message: 'Ya existe un pedido en curso para este cliente.',
                        visible: true,
                        onTouchOutside: () => { },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => { myThis.limpiarError(); }
                        }
                    });
                }, alertTimeout);
            } else {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error desconocido',
                        message: 'Ha ocurrido un error inesperado al seleccionar el trámite',
                        visible: true,
                        onTouchOutside: () => { },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => { myThis.limpiarError(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-403)',
                    visible: true,
                    onTouchOutside: () => { },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de barras
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        let tramites = [];
        if (this.state.arrayTramites) {
            this.state.arrayTramites.forEach((tramite, contador) => {
                tramites.push(<View
                    style={{ borderBottomWidth: 1, }}
                    key={contador}>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <TouchableOpacity
                            onPress={() => { this.pushTramite(tramite) }}
                            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontSize: 25, marginVertical: 15 }}>{tramite.d_tramite}</Text>
                        </TouchableOpacity>
                    </View>
                </View>);
            });
        }
        return (
            <View style={styles.container}>
                <View style={styles.viewContainer}>
                    <Text style={styles.viewText}>Introduzca el código del cliente</Text>
                </View>
                <Text>
                    Cliente
                </Text>
                <View style={styles.inputRow}>
                    <TouchableOpacity
                        onPress={() => { this.scanCode(); }}
                        style={{ alignSelf: 'center', marginLeft: 5 }}>
                        <Feather name={"camera"} size={30} color="#d24040" />
                    </TouchableOpacity>
                    <TextInput
                        placeholder="Código de cliente"
                        placeholderTextColor="#d24040"
                        returnKeyType="go"
                        onSubmitEditing={() => { this.getCodigoCliente(); }}
                        onChangeText={this.handleCodigoCliente}
                        style={styles.input}
                        underlineColorAndroid={'transparent'}
                        ref={(input) => this.clienteInput = input}
                        selectTextOnFocus
                        value={this.state.codigoCliente}
                    />
                    <TouchableOpacity
                        onPress={() => {
                            this.setState({ nombreCliente: "", codigoCliente: "", arrayTramites: [] });
                            this.handleCodigoCliente("");
                        }}
                        style={{ alignSelf: 'center', marginRight: 5 }}>
                        <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                    </TouchableOpacity>
                </View>
                {this.state.nombreCliente ? <View style={{}}>
                    <Text>
                        Nombre
                    </Text>
                    <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, }]}>
                            <Text style={{ fontSize: 17, fontWeight: "700" }}>{this.state.nombreCliente}</Text>
                        </View>
                    </View>
                </View> : <View></View>}

                {this.state.nombreCliente ? <View style={{ marginTop: 20 }}>
                    <Text>
                        Trámites <Text style={{ fontWeight: "700" }}>({this.state.arrayTramites.length})</Text>
                    </Text>
                    <View style={{ height: "65%", borderWidth: 1, borderRadius: 2, borderColor: '#d24040', marginTop: 2 }}>
                        <ScrollView>{tramites}</ScrollView>
                    </View>
                </View> : <View></View>}
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Nueva Venta',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
            //headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 22
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    }
});

export default withNavigationFocus(NuevaVenta);