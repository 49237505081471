import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    TextInput,
    Keyboard,
    BackHandler,
    Vibration,
    ScrollView,
    KeyboardAvoidingView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import Spinner from "react-native-loading-spinner-overlay";
import { alertTimeout, getDatosArticuloUbi, getUbicacion, getDesubicar, sendWebhook } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class Desubicar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscanerArticulo: false,
            mostrarEscanerUbicacion: false,
            spinner: false,
            codigoArticulo: "",
            codigoUbicacion: "",
            cantidad: "0",
            enableArticulo: true,
            enableUbicacion: true,
            nombreArticulo: "",
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        setTimeout(() => {
            this.ubicacionInput.focus();
        }, 550);
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleBackPress = () => {
        if (this.state.mostrarEscanerUbicacion) {
            this.setState({ mostrarEscanerUbicacion: false });
            return true;
        } else if (this.state.mostrarEscanerArticulo) {
            this.setState({ mostrarEscanerArticulo: false });
            return true;
        } else {
            return false;
        }
    }

    goBack = () => {
        if (!this.handleBackPress()) {
            this.props.navigation.goBack();
        }
    }

    handleCodigoArticulo = (codigoArticulo) => {
        this.setState({ codigoArticulo: codigoArticulo });
    }

    handleCodigoUbicacion = (codigoUbicacion) => {
        this.setState({ codigoUbicacion: codigoUbicacion });
    }

    handleCantidad = (cantidad) => {
        if (cantidad == "") {
            this.setState({ cantidad: "0" });
        } else {
            this.setState({ cantidad: cantidad.replace(/[^0-9]/g, '').replace(/^[0|\D]*/, '') });
        }
    }

    comprobarUbicacion = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        sendWebhook(":negative_squared_cross_mark: Desubicando artículo: " + this.state.codigoUbicacion);
        getUbicacion(myThis.state.codigoUbicacion, function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "3101") {
                myThis.setState({
                    enableUbicacion: false,
                }, () => {
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                });
            } else {
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Ubicacion no encontrada',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.comprobarUbicacion(); }
                        },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => { myThis.limpiarError(); myThis.ubicacionInput.focus(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.comprobarUbicacion(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); myThis.ubicacionInput.focus(); }
                    }
                });
            }, alertTimeout);
        });
    }

    comprobarArticulo = () => {
        Keyboard.dismiss();
        console.warn("Comprobando datos articulo: " + this.state.codigoArticulo);
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getDatosArticuloUbi(myThis.state.codigoArticulo, function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.Mensaje && respuesta.Mensaje.DesArt) {
                myThis.setState({
                    enableArticulo: false,
                    nombreArticulo: respuesta.Mensaje.DesArt,
                }, () => {
                    setTimeout(() => {
                        myThis.cantidadInput.focus();
                    }, 550);
                });
            } else {
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Artículo no encontrado',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.comprobarArticulo(); }
                        },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => { myThis.limpiarError(); myThis.articuloInput.focus(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-402)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.comprobarArticulo(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); myThis.articuloInput.focus(); }
                    }
                });
            }, alertTimeout);
        });
    }

    desubicar = () => {
        Keyboard.dismiss();
        console.warn("Desubicando: " + this.state.cantidad + " unidades de " + this.state.codigoArticulo + " en " + this.state.codigoUbicacion);
        const myThis = this;
        myThis.setSpinnerVisible(true);
        getDesubicar(this.state.codigoUbicacion, this.state.codigoArticulo, this.state.cantidad, function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "3401") {
                const mensaje = "\nUbicación: " + myThis.state.codigoUbicacion +
                    "\nArticulo: " + myThis.state.codigoArticulo +
                    "\nCantidad: " + myThis.state.cantidad
                setTimeout(() => {
                    myThis.setError({
                        title: 'Articulo desubicado correctamente',
                        message: mensaje,
                        visible: true,
                        onTouchOutside: () => { },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => { myThis.goBack(); }
                        }
                    });
                }, alertTimeout);
            } else {
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'No se ha podido desubicar',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.desubicar(); }
                        },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => { myThis.limpiarError(); myThis.ubicacionInput.focus(); }
                        }
                    });
                }, alertTimeout);
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            setTimeout(() => {
                myThis.setError({
                    title: 'Error',
                    message: 'Error en la comunicación con el servidor (-401)',
                    visible: true,
                    onTouchOutside: () => { },
                    negativeButton: {
                        title: 'Reintentar',
                        onPress: () => { myThis.limpiarError(); myThis.desubicar(); }
                    },
                    positiveButton: {
                        title: 'OK',
                        onPress: () => { myThis.limpiarError(); }
                    }
                });
            }, alertTimeout);
        });
    }

    scanCodeUbicacion = () => {
        this.setState({ mostrarEscanerUbicacion: true });
    }

    scanCodeArticulo = () => {
        this.setState({ mostrarEscanerArticulo: true });
    }

    handleBarCodeScannedUbicacion = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({
            mostrarEscanerUbicacion: false,
            codigoUbicacion: data
        }, () => {
            this.comprobarUbicacion();
        });
    }

    handleBarCodeScannedArticulo = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({
            mostrarEscanerArticulo: false,
            codigoArticulo: data,
            nombreArticulo: ""
        }, () => {
            this.comprobarArticulo();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({ spinner: visible });
    }

    bajarCantidad = () => {
        if (Number(this.state.cantidad) > 0) {
            this.setState({ cantidad: String(Number(this.state.cantidad) - 1) });
        }
    }

    subirCantidad = () => {
        this.setState({ cantidad: String(Number(this.state.cantidad) + 1) });
    }

    render() {
        if (this.state.mostrarEscanerUbicacion) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Ubicacion
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscanerUbicacion ? this.handleBarCodeScannedUbicacion : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscanerUbicacion: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        } else if (this.state.mostrarEscanerArticulo) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de Articulo
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscanerArticulo ? this.handleBarCodeScannedArticulo : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscanerArticulo: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <ScrollView style={styles.container}>
                <KeyboardAvoidingView behavior="padding" style={styles.container}>
                    <View style={styles.viewContainer}>
                        <Text style={styles.viewText}>Desubicar artículo</Text>
                    </View>
                    <Text>
                        Ubicación
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCodeUbicacion(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}
                            disabled={!this.state.enableUbicacion}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de ubicación"
                            placeholderTextColor="#d24040"
                            returnKeyType="next"
                            onSubmitEditing={() => { this.comprobarUbicacion(); }}
                            onChangeText={this.handleCodigoUbicacion}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            editable={this.state.enableUbicacion}
                            ref={(input) => this.ubicacionInput = input}
                            selectTextOnFocus
                            value={this.state.codigoUbicacion}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoUbicacion: "", enableUbicacion: true }, () => {
                                    setTimeout(() => {
                                        this.ubicacionInput.focus();
                                    }, 550);
                                });
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>
                    <Text>
                        Artículo
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.scanCodeArticulo(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}
                            disabled={!this.state.enableArticulo}>
                            <Feather name={"camera"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca código de artículo"
                            placeholderTextColor="#d24040"
                            returnKeyType="next"
                            onSubmitEditing={() => { this.comprobarArticulo(); }}
                            onChangeText={this.handleCodigoArticulo}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.articuloInput = input}
                            editable={this.state.enableArticulo}
                            selectTextOnFocus
                            value={this.state.codigoArticulo}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ codigoArticulo: "", nombreArticulo: "", enableArticulo: true }, () => {
                                    setTimeout(() => {
                                        this.articuloInput.focus();
                                    }, 550);
                                });
                            }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>
                    {this.state.codigoArticulo && this.state.nombreArticulo ? <View style={{ marginTop: -10, marginBottom: 15, borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                        <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                            <Text style={{ fontSize: 15 }}>Nombre:</Text>
                            <Text style={{ fontSize: 15, paddingLeft: 15 }}>{this.state.nombreArticulo}</Text>
                        </View>
                    </View> : <View></View>}
                    <Text>
                        Cantidad
                    </Text>
                    <View style={styles.inputRow}>
                        <TouchableOpacity
                            onPress={() => { this.bajarCantidad(); }}
                            style={{ alignSelf: 'center', marginLeft: 5 }}>
                            <Feather name={"arrow-down-circle"} size={30} color="#d24040" />
                        </TouchableOpacity>
                        <TextInput
                            placeholder="Introduzca cantidad a ubicar"
                            placeholderTextColor="#d24040"
                            returnKeyType="go"
                            onSubmitEditing={() => { this.desubicar(); }}
                            onChangeText={this.handleCantidad}
                            style={styles.input}
                            underlineColorAndroid={'transparent'}
                            ref={(input) => this.cantidadInput = input}
                            keyboardType={'number-pad'}
                            selectTextOnFocus
                            value={this.state.cantidad}
                        />
                        <TouchableOpacity
                            onPress={() => { this.subirCantidad(); }}
                            style={{ alignSelf: 'center', marginRight: 5 }}>
                            <Feather name={"arrow-up-circle"} size={30} color="#d24040" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.desubicar(); }}>
                            <Text style={styles.buttonText}>
                                Desubicar
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <ConfirmDialog
                        title={this.state.dialogError.title}
                        message={this.state.dialogError.message}
                        visible={this.state.dialogError.visible}
                        onTouchOutside={this.state.dialogError.onTouchOutside}
                        negativeButton={this.state.dialogError.negativeButton}
                        positiveButton={this.state.dialogError.positiveButton}
                    />
                    <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
                </KeyboardAvoidingView>
            </ScrollView>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Desubicar Artículo',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
            //headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 25
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    }
});

export default withNavigationFocus(Desubicar);