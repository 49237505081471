import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import Login from "./Views/Login";
import MenuPrincipal from "./Views/MenuPrincipal";
import MenuProcesosAlmacen from "./Views/MenuProcesosAlmacen";
import ConsultarArticulo from "./Views/ConsultarArticulo";
import MenuUbicaciones from "./Views/MenuUbicaciones";
import Ubicar from "./Views/Ubicar";
import Desubicar from "./Views/Desubicar";
import ConsultarArticuloUbi from "./Views/ConsultarArticuloUbi";
import ConsultarUbicacion from "./Views/ConsultarUbicacion";
import Mover from "./Views/Mover";
import ProcesarOrdenes from "./Views/ProcesarOrdenes";
import ConfigurarPDA from "./Views/ConfigurarPDA";
import NuevaVenta from "./Views/NuevaVenta";
import MenuVentas from "./Views/MenuVentas";
import VentasPendientes from "./Views/VentasPendientes";
import ProcesarVenta from "./Views/ProcesarVenta";

if (true) {
    console.warn = () => { };
    console.log = () => { };
    console.error = () => { };
}

const App = createStackNavigator(
    {
        Login: { screen: Login },
        ConfigurarPDA: { screen: ConfigurarPDA },
        MenuPrincipal: { screen: MenuPrincipal },
        MenuProcesosAlmacen: { screen: MenuProcesosAlmacen },
        ConsultarArticulo: { screen: ConsultarArticulo },
        MenuUbicaciones: { screen: MenuUbicaciones },
        Ubicar: { screen: Ubicar },
        Desubicar: { screen: Desubicar },
        ConsultarArticuloUbi: { screen: ConsultarArticuloUbi },
        ConsultarUbicacion: { screen: ConsultarUbicacion },
        Mover: { screen: Mover },
        ProcesarOrdenes: { screen: ProcesarOrdenes },
        MenuVentas: { screen: MenuVentas },
        NuevaVenta: { screen: NuevaVenta },
        VentasPendientes: { screen: VentasPendientes },
        ProcesarVenta: { screen: ProcesarVenta }
    },
    {
        initialRouteName: 'Login',
        defaultNavigationOptions: {
            headerTintColor: '#FFF',
            headerStyle: {
                backgroundColor: '#FF6161',
                borderWidth: 0,
                borderBottomWidth: 3,
                borderBottomColor: 'white'
            }
        }
    }
);

const AppContainer = createAppContainer(App);

export default AppContainer;
