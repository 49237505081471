import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Text,
    TextInput,
    Keyboard,
    BackHandler,
    Vibration,
    ScrollView
} from "react-native";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import Spinner from "react-native-loading-spinner-overlay";
import { alertTimeout, getDatosArticulo, sendWebhook } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class ConsultarArticulo extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mostrarEscaner: false,
            spinner: false,
            codigoArticulo: "",
            articulo: {
                "CodArt": "Esperando código...",
                "DesArt": "Esperando código...",
                "UniVenta": "Esperando código...",
                "Peso": "Esperando código...",
                "Volumen": "Esperando código...",
                "Separador": "Esperando código...",
                "PosJaula": "Esperando código...",
                "Existencias": "Esperando código..."
            },
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingRight: 5, paddingLeft: 8 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        setTimeout(() => {
            this.articuloInput.focus();
        }, 550);
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    handleBackPress = () => {
        if (this.state.mostrarEscaner) {
            this.setState({ mostrarEscaner: false });
            return true;
        } else {
            return false;
        }
    }

    goBack = () => {
        if (!this.handleBackPress()) {
            this.props.navigation.goBack();
        }
    }

    handleCodigoArticulo = (codigoArticulo) => {
        this.setState({ codigoArticulo: codigoArticulo });
    }

    getArticulo = () => {
        Keyboard.dismiss();
        const myThis = this;
        myThis.setSpinnerVisible(true);
        sendWebhook(":mag_right: Consultando artículo: " + this.state.codigoArticulo);
        getDatosArticulo(this.state.codigoArticulo, function (respuesta) {
            myThis.setSpinnerVisible(false);
            if (respuesta.Mensaje && respuesta.Mensaje.Estado && respuesta.Mensaje.Estado == "5201"
                && respuesta.Mensaje.Articulo) {
                myThis.setState({
                    articulo: {
                        "CodArt": respuesta.Mensaje.Articulo.CodArt,
                        "DesArt": respuesta.Mensaje.Articulo.DesArt.trim(),
                        "UniVenta": String(respuesta.Mensaje.Articulo.UniVenta).replace(/\s/g, ''),
                        "Peso": String(respuesta.Mensaje.Articulo.Peso).replace(/\s/g, ''),
                        "Volumen": String(respuesta.Mensaje.Articulo.Volumen).replace(/\s/g, ''),
                        "Separador": String(respuesta.Mensaje.Articulo.Separador).replace(/\s/g, ''),
                        "PosJaula": String(respuesta.Mensaje.Articulo.PosJaula).replace(/\s/g, ''),
                        "Existencias": String(respuesta.Mensaje.Articulo.Existencias).replace(/\s/g, '')
                    }
                }, () => {
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                });
            } else {
                myThis.setState({
                    articulo: {
                        "CodArt": "NO ENCONTRADO",
                        "DesArt": "",
                        "UniVenta": "Esperando código...",
                        "Peso": "Esperando código...",
                        "Volumen": "Esperando código...",
                        "Separador": "Esperando código...",
                        "PosJaula": "Esperando código...",
                        "Existencias": "Esperando código..."
                    }
                }, () => {
                    setTimeout(() => {
                        myThis.articuloInput.focus();
                    }, 550);
                });
            }
        }, function (error) {
            myThis.setSpinnerVisible(false);
            myThis.setState({
                articulo: {
                    "CodArt": "Esperando código...",
                    "DesArt": "Esperando código...",
                    "UniVenta": "Esperando código...",
                    "Peso": "Esperando código...",
                    "Volumen": "Esperando código...",
                    "Separador": "Esperando código...",
                    "PosJaula": "Esperando código...",
                    "Existencias": "Esperando código..."
                }
            }, () => {
                setTimeout(() => {
                    myThis.setError({
                        title: 'Error',
                        message: 'Error en la comunicación con el servidor (-201)',
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: 'Reintentar',
                            onPress: () => { myThis.limpiarError(); myThis.getArticulo(); }
                        },
                        positiveButton: {
                            title: 'OK',
                            onPress: () => {
                                myThis.limpiarError();
                                setTimeout(() => {
                                    myThis.articuloInput.focus();
                                }, 550);
                            }
                        }
                    });
                }, alertTimeout);
            });
        });
    }

    scanCode = () => {
        this.setState({ mostrarEscaner: true });
    }

    handleBarCodeScanned = ({ type, data }) => {
        Vibration.vibrate();
        this.setState({ mostrarEscaner: false, codigoArticulo: data }, () => {
            this.getArticulo();
        });
    }

    setSpinnerVisible = (visible) => {
        this.setState({ spinner: visible });
    }

    render() {
        if (this.state.mostrarEscaner) {
            return (
                <View style={styles.container}>
                    <View style={[styles.viewContainer, { height: "10%" }]}>
                        <Text style={styles.viewText}>
                            Escanear código de barras
                        </Text>
                    </View>
                    <View style={{ height: "70%", borderWidth: 5, borderColor: '#FF9190', }}>
                        <Text style={{ position: "absolute", top: "40%", left: "0%", width: "100%", textAlign: "center" }}>
                            Cámara no disponible
                        </Text>
                        <BarCodeScanner
                            onBarCodeScanned={this.state.mostrarEscaner ? this.handleBarCodeScanned : undefined}
                            style={[StyleSheet.absoluteFill]}
                        />
                        <View style={{ top: "50%", left: "25%", height: 2, width: "50%", backgroundColor: "#FF9190" }}></View>
                    </View>
                    <View style={{ height: "10%" }}>
                        <TouchableOpacity
                            style={[styles.buttonContainer]}
                            onPress={() => { this.setState({ mostrarEscaner: false }); }}>
                            <Text style={styles.buttonText}>
                                Cancelar
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        return (
            <ScrollView style={styles.container}>
                <View style={styles.viewContainer}>
                    <Text style={styles.viewText}>Consulta datos artículo</Text>
                </View>
                <Text>
                    Artículo
                </Text>
                <View style={styles.inputRow}>
                    <TouchableOpacity
                        onPress={() => { this.scanCode(); }}
                        style={{ alignSelf: 'center', marginLeft: 5 }}>
                        <Feather name={"camera"} size={30} color="#d24040" />
                    </TouchableOpacity>
                    <TextInput
                        placeholder="Introduzca código de artículo"
                        placeholderTextColor="#d24040"
                        returnKeyType="go"
                        onSubmitEditing={() => { this.getArticulo(); }}
                        onChangeText={this.handleCodigoArticulo}
                        style={styles.input}
                        underlineColorAndroid={'transparent'}
                        ref={(input) => this.articuloInput = input}
                        selectTextOnFocus
                        value={this.state.codigoArticulo}
                    />
                    <TouchableOpacity
                        onPress={() => {
                            this.setState({
                                codigoArticulo: "",
                                articulo: {
                                    "CodArt": "Esperando código...",
                                    "DesArt": "Esperando código...",
                                    "UniVenta": "Esperando código...",
                                    "Peso": "Esperando código...",
                                    "Volumen": "Esperando código...",
                                    "Separador": "Esperando código...",
                                    "PosJaula": "Esperando código...",
                                    "Existencias": "Esperando código..."
                                }
                            }, () => {
                                setTimeout(() => {
                                    this.articuloInput.focus();
                                }, 550);
                            });
                            this.handleCodigoArticulo("");
                        }}
                        style={{ alignSelf: 'center', marginRight: 5 }}>
                        <MaterialCommunityIcons name={"close"} size={30} color="#d24040" />
                    </TouchableOpacity>
                </View>
                {/*<View style={{ height: "5%" }}>
                    <TouchableOpacity
                        style={[styles.buttonContainer, { marginTop: 0 }]}
                        onPress={() => { this.getArticulo(); }}>
                        <Text style={[styles.buttonText, { fontSize: 25 }]}>
                            Consultar
                        </Text>
                    </TouchableOpacity>
                </View>*/}
                <View style={{ marginTop: 10, borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Articulo:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.CodArt}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, }]}>
                        <Text style={{ fontSize: 20 }}>{this.state.articulo.DesArt}</Text>
                    </View>
                </View>

                <View style={{ borderWidth: 1, borderRadius: 2, borderColor: '#d24040', }}>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Unidades/bolsa:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.UniVenta}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Peso:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.Peso}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Volumen:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.Volumen}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Separador:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.Separador}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Posición jaula:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.PosJaula}</Text>
                    </View>
                    <View style={[{ marginHorizontal: 15, flexDirection: "row", alignItems: 'center' }]}>
                        <Text style={{ fontSize: 15 }}>Existencias:</Text>
                        <Text style={{ fontSize: 20, paddingLeft: 15 }}>{this.state.articulo.Existencias}</Text>
                    </View>
                </View>
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </ScrollView>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => (
            <TouchableOpacity style={{ paddingRight: 5, paddingLeft: 8 }}>
                <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
            </TouchableOpacity>
        );
        return {
            title: 'Consultar Artículo',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft)
        };
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 5,
    },
    buttonContainer: {
        backgroundColor: '#FF9190',
        paddingVertical: 18,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#FF9190',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 30
    },
    viewContainer: {
        paddingVertical: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewText: {
        fontWeight: '700',
        fontSize: 25
    },
    inputRow: {
        flexDirection: 'row',
        height: 45,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d24040',
        marginBottom: 20
    },
    input: {
        flex: 1,
        height: 45,
        paddingHorizontal: 10,
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: '#d24040',
    }
});

export default withNavigationFocus(ConsultarArticulo);