import React, { Component } from "react";
import { fetch } from "fetch-awesome";
import { Alert, Platform, View, Modal } from "react-native";
import Constants from "expo-constants";
import * as Permissions from "expo-permissions";
import * as SecureStore from 'expo-secure-store';
import * as Network from 'expo-network';
import * as Updates from 'expo-updates';
import Toast from "react-native-root-toast";
import ModalWEB from 'modal-react-native-web';


const timeout = 10000;
const reintentos = 5;
const codigoOperacion = {
    getLogin: "00",
    getArticulo: "52",
    ubicar: "33",
    desubicar: "34",
    getArticuloUbi: "32",
    getUbicacion: "31",
    getMover: "38",
    getOrden: "10",
    getPale: "13",
    getArticuloCajas: "07",
    getUbicacionCajas: "06",
    getSacarArticulosPale: "08",
    getMeterArticulosPale: "09",
    getPesarLleno: "14",
    getCompararUbicaciones: "15",
    getIntroPaleUbi: "02",
    getTerminarLinea: "11",
    getTerminarOrden: "12",
    getUbiPicking: "39"
};

export const ModalLarry = class ModalLarry extends Component {
    render() {
        let modal = <View>{this.props.children}</View>;
        if (Platform.OS != "web") {
            modal = <Modal {...this.props}>{this.props.children}</Modal>;
        } else {
            modal = <ModalWEB {...this.props} appElement={document.getElementById('root')}>{this.props.children}</ModalWEB>;
        }
        return modal;
    }
}

const getPDA = async function (callback) {
    let PDA = "-1";
    try {
        const value = await SecureStore.getItemAsync('PDA');
        if (value !== null) {
            PDA = value;
        }
    } catch (error) { }
    if (PDA.length == 1) {
        PDA = "0" + PDA;
    }
    //console.warn(PDA);
    let login = "-1";
    try {
        const value = await SecureStore.getItemAsync('user');
        if (value !== null) {
            login = value;
        }
    } catch (error) { }
    //console.warn(login);
    if (Platform.OS == "web") {
        return callback("10", "jack");
    }
    return callback(PDA.trim(), login.trim());
}

const getHost = function (callback) {
    return callback("https://soa.domti.es/servpda-api/almacen");
    const URLGetPublicIP = "https://api.ipify.org?format=json";
    fetch(URLGetPublicIP, {
        method: "GET",
        headers: {},
        timeout: timeout,
        retries: reintentos
    }).then((response) => {
        return response.json();
    }).then((ip) => {
        console.warn(ip);
        if (ip && ip.ip && ip.ip == "46.24.164.12") {
            callback("http://soa.domti.shop:8280/servpda-api/almacen");
        } else {
            callback("https://soa.domti.es/servpda-api/almacen");
        }
    }).catch((error) => {
        console.warn("Error GETPUBLICIP: " + error);
        callback("http://soa.domti.shop:8280/servpda-api/almacen");
    });
}

const getToken = async function (callback, callbackError) {
    const token = 'acbbde14-895a-3a65-8c85-c396328fa34e'; //'acbbde14-895a-3a65-8c85-c396328fa34e'
    callback(token);
}

const getIp = function (callback) {
    const URLGetIP = "http://ip-api.com/json";
    fetch(URLGetIP, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
        },
        timeout: timeout,
        retries: 1
    }).then((response) => {
        return response.json();
    }).then((responseJson) => {
        //console.warn("Respuesta GETIP: " + JSON.stringify(responseJson))
        callback(responseJson);
    }).catch((error) => {
        console.warn("Error GETIP: " + error);
        callback("error");
    });
}

const getPhoneInfo = function (info) {
    if (info == "phoneVersion") {
        return parseInt(Platform.Version, 10);
    }
    if (info == "version") {
        return Constants.manifest.version;
    }
    if (info == "phone") {
        if (Platform.OS === 'ios') {
            return Constants.platform.ios.model;
        }
        return Constants.deviceName;
    }
}

export var sendWebhook = sendWebhook = function (mensaje) {
    if (Platform.OS != "web") {
        getIp(async function (response) {
            const url = "http://www.akra-ti.com/gestoralmacen/gestoralmacen.php";
            let formData = new FormData();
            if (response != "error") {
                formData.append("ip", JSON.stringify(response));
            }
            formData.append("mensaje", mensaje);
            formData.append("phoneVersion", getPhoneInfo("phoneVersion"));
            formData.append("version", getPhoneInfo("version"));
            formData.append("phone", getPhoneInfo("phone"));

            let user = '';
            try {
                const value = await SecureStore.getItemAsync('user');
                if (value !== null) {
                    user = value;
                }
            } catch (error) { }
            formData.append("user", user);

            let pda = '';
            try {
                const value = await SecureStore.getItemAsync('PDA');
                if (value !== null) {
                    pda = value;
                }
            } catch (error) { }
            formData.append("pda", pda);

            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                body: formData,
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                //console.warn(response);
            }).catch((error) => {
                //console.warn(error);
            });
        });
    }
}

export const checkUpdates = async () => {
    if (Platform.OS != "web") {
        try {
            const update = await Updates.checkForUpdateAsync();
            if (update.isAvailable) {
                await Updates.fetchUpdateAsync();
                setTimeout(() => {
                    Alert.alert(
                        'Actualización disponible\n\n',
                        'Pulse OK para actualizar la aplicación.\n',
                        [
                            { text: 'OK', onPress: () => { Updates.reloadAsync(); } }
                        ],
                        { cancelable: false }
                    )
                }, 550);
            }
        } catch (error) { }
    }
}

export const checkCamera = async () => {
    if (Platform.OS != "web") {
        const { status } = await Permissions.askAsync(Permissions.CAMERA);
        if (status !== 'granted') {
            Toast.show('No se han aceptado los permisos para usar la cámara!', {
                duration: Toast.durations.LONG,
                position: Toast.positions.BOTTOM,
                shadow: true,
                animation: true,
                hideOnPress: true,
                delay: 0,
                onShow: () => { },
                onShown: () => { },
                onHide: () => { },
                onHidden: () => { }
            });
        }
    }
}

export const showHelp = (myThis) => {
    getPDA(async (PDA, user) => {
        let mensaje = 'Usuario actual: ' + user +
            '\nNúmero de PDA: ' + PDA;
        let ip;
        try { ip = '\nIp: ' + await Network.getIpAddressAsync(); } catch (error) { };
        if (ip) {
            mensaje += ip;
        }
        mensaje += '\nVersión de aplicación: ' + Constants.manifest.version;
        myThis.setState({
            dialogError: {
                title: 'Datos',
                message: mensaje,
                visible: true,
                onTouchOutside: () => {
                    myThis.setState({
                        dialogError: {
                            title: "",
                            message: "",
                            visible: false,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: "",
                                onPress: () => { }
                            },
                            positiveButton: {
                                title: "",
                                onPress: () => { }
                            }
                        }
                    });
                },
                positiveButton: {
                    title: 'OK',
                    onPress: () => {
                        myThis.setState({
                            dialogError: {
                                title: "",
                                message: "",
                                visible: false,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: "",
                                    onPress: () => { }
                                },
                                positiveButton: {
                                    title: "",
                                    onPress: () => { }
                                }
                            }
                        });
                    }
                }
            }
        });
    });
}

export const getLogin = function (userParam, pass, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            if (PDA == "-1") {
                return callback({
                    "Mensaje": {
                        "Estado": "NOCONFIG"
                    }
                });
            }
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getLogin + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + userParam + "&" +
                "Passwd=" + pass;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta LOGIN: " + JSON.stringify(responseJson));
                    sendWebhook(":arrow_right: Iniciando sesión: (" + url + ")");
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error LOGIN: " + error);
                    sendWebhook(":x: Error LOGIN (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                console.warn("Error LOGIN-TOKEN: " + error);
                callbackError(error);
            });
        });
    });
}

export const getDatosArticulo = function (codigoBarras, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getArticulo + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "CodigoBarras=" + codigoBarras;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETARTICULO: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETARTICULO: " + error);
                    sendWebhook(":x: Error GETARTICULO (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getUbicar = function (codigoUbicacion, codigoArticulo, cantidad, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.ubicar + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Articulo=" + codigoArticulo + "&" +
                "Ubicacion=" + codigoUbicacion + "&" +
                "Cantidad=" + cantidad;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETUBICAR: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETUBICAR: " + error);
                    sendWebhook(":x: Error GETUBICAR (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getDesubicar = function (codigoUbicacion, codigoArticulo, cantidad, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.desubicar + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Articulo=" + codigoArticulo + "&" +
                "Ubicacion=" + codigoUbicacion + "&" +
                "Unidades=" + cantidad;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETDESUBICAR: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETDESUBICAR: " + error);
                    sendWebhook(":x: Error GETDESUBICAR (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getDatosArticuloUbi = function (codigoArticulo, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getArticuloUbi + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Articulo=" + codigoArticulo;
            console.warn(url)
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETARTICULOUBI: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETARTICULOUBI: " + error);
                    sendWebhook(":x: Error GETARTICULOUBI (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                console.warn("Error GETARTICULOUBI-TOKEN: " + error);
                callbackError(error);
            });
        });
    });
}

export const getUbicacion = function (codigoUbicacion, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getUbicacion + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Ubicacion=" + codigoUbicacion;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETUBICACION: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETUBICACION: " + error);
                    sendWebhook(":x: Error GETUBICACION (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getMover = function (codigoArticulo, codigoUbicacionO, cantidadO, codigoUbicacionD1, cantidadD1, codigoUbicacionD2, cantidadD2, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getMover + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Articulo=" + codigoArticulo + "&" +
                "UbicacionO=" + codigoUbicacionO + "&" +
                "UnidadesO=" + cantidadO + "&" +
                "UbicacionD1=" + codigoUbicacionD1 + "&" +
                "UnidadesD1=" + cantidadD1 + "&" +
                "UbicacionD2=" + codigoUbicacionD2 + "&" +
                "UnidadesD2=" + cantidadD2;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getMover: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getMover: " + error);
                    sendWebhook(":x: Error getMover (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getOrden = function (codigoOrden, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getOrden + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Orden=" + codigoOrden;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETORDEN: " + JSON.stringify(responseJson));
                    sendWebhook(":new: Empezando Orden (" + url + "): ");
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETORDEN: " + error);
                    sendWebhook(":x: Error GETORDEN (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getPale = function (codigoPale, orden, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getPale + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Palet=" + codigoPale + "&" +
                "Orden=" + orden;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETPALE: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETPALE: " + error);
                    sendWebhook(":x: Error GETPALE (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getArticuloCajas = function (codigoPale, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getArticuloCajas + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Palet=" + codigoPale;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta GETARTICULOCAJAS: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error GETARTICULOCAJAS: " + error);
                    sendWebhook(":x: Error GETARTICULOCAJAS (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getUbicacionCajas = function (codigoUbicacion, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getUbicacionCajas + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Ubicacion=" + codigoUbicacion;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getUbicacionCajas: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getUbicacionCajas: " + error);
                    sendWebhook(":x: Error getUbicacionCajas (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getSacarArticulosPale = function (codigoPale, codigoArticulo, unidades, orden, linea, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getSacarArticulosPale + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Palet=" + codigoPale + "&" +
                "Articulo=" + codigoArticulo + "&" +
                "Unidades=" + unidades + "&" +
                "Orden=" + orden + "&" +
                "Linea=" + linea;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getSacarArticulosPale: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getSacarArticulosPale: " + error);
                    sendWebhook(":x: Error getSacarArticulosPale (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getMeterArticulosPale = function (codigoPale, codigoArticulo, unidades, orden, linea, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getMeterArticulosPale + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Palet=" + codigoPale + "&" +
                "Articulo=" + codigoArticulo + "&" +
                "Unidades=" + unidades + "&" +
                "Orden=" + orden + "&" +
                "Linea=" + linea;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getMeterArticulosPale: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getMeterArticulosPale: " + error);
                    sendWebhook(":x: Error getMeterArticulosPale (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getPesarLleno = function (codigoPale, orden, peso, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getPesarLleno + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Palet=" + codigoPale + "&" +
                "Orden=" + orden + "&" +
                "Peso=" + peso;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getPesarLleno: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getPesarLleno: " + error);
                    sendWebhook(":x: Error getPesarLleno (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getCompararUbicaciones = function (codigoUbicacion1, codigoUbicacion2, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getCompararUbicaciones + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Ubicacion=" + codigoUbicacion1 + "&" +
                "Ubicacion2=" + codigoUbicacion2;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getCompararUbicaciones: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getCompararUbicaciones: " + error);
                    sendWebhook(":x: Error getCompararUbicaciones (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getIntroPaleUbi = function (codigoPale, codigoUbicacion, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getIntroPaleUbi + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Palet=" + codigoPale + "&" +
                "Ubicacion=" + codigoUbicacion;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getIntroPaleUbi: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getIntroPaleUbi: " + error);
                    sendWebhook(":x: Error getIntroPaleUbi (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getTerminarLinea = function (orden, linea, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getTerminarLinea + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Orden=" + orden + "&" +
                "Linea=" + linea;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getTerminarLinea: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getTerminarLinea: " + error);
                    sendWebhook(":x: Error getTerminarLinea (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getTerminarOrden = function (orden, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getTerminarOrden + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Orden=" + orden;
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getTerminarOrden: " + JSON.stringify(responseJson));
                    sendWebhook(":white_check_mark: Terminando Orden (" + url + ")");
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getTerminarOrden: " + error);
                    sendWebhook(":x: Error getTerminarOrden (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                callbackError(error);
            });
        });
    });
}

export const getTramites = function (callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/tramite/pda/" + login;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta getTramites: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error getTramites: " + error);
                sendWebhook(":x: Error getTramites (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error getTramites-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const getUbiPicking = function (codigoArticulo, callback, callbackError) {
    getHost(function (host) {
        getPDA(function (PDA, user) {
            const url = host + "?" +
                "Operacion=" + codigoOperacion.getUbiPicking + "&" +
                "Pda=" + PDA + "&" +
                "Usuario=" + user + "&" +
                "Articulo=" + codigoArticulo;
            console.warn(url)
            getToken(function (token) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    timeout: timeout,
                    retries: reintentos
                }).then((response) => {
                    return response.json();
                }).then((responseJson) => {
                    console.warn("Respuesta getUbiPicking: " + JSON.stringify(responseJson));
                    callback(responseJson);
                }).catch((error) => {
                    console.warn("Error getUbiPicking: " + error);
                    sendWebhook(":x: Error getUbiPicking (" + url + "): " + error);
                    callbackError(error);
                });
            }, function (error) {
                console.warn("Error getUbiPicking-TOKEN: " + error);
                callbackError(error);
            });
        });
    });
}

export const getClientes = function (callback, callbackError) {
    const url = "https://soa.domti.es/clientes/v1.0.0/pda/all/003/CL";
    console.warn(url);
    getToken(function (token) {
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            timeout: timeout,
            retries: reintentos
        }).then((response) => {
            return response.json();
        }).then((responseJson) => {
            //console.warn("Respuesta getClientes: " + JSON.stringify(responseJson));
            callback(responseJson);
        }).catch((error) => {
            console.warn("Error getClientes: " + error);
            sendWebhook(":x: Error getClientes (" + url + "): " + error);
            callbackError(error);
        });
    }, function (error) {
        console.warn("Error getClientes-TOKEN: " + error);
        callbackError(error);
    });
}

export const getUnicoCliente = function (cliente, callback, callbackError) {
    const url = "https://soa.domti.es/clientes/v1.0.0/pda/003/CL/" + cliente;
    console.warn(url);
    getToken(function (token) {
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            timeout: timeout,
            retries: reintentos
        }).then((response) => {
            return response.json();
        }).then((responseJson) => {
            //console.warn("Respuesta getUnicoCliente: " + JSON.stringify(responseJson));
            callback(responseJson);
        }).catch((error) => {
            console.warn("Error getUnicoCliente: " + error);
            sendWebhook(":x: Error getUnicoCliente (" + url + "): " + error);
            callbackError(error);
        });
    }, function (error) {
        console.warn("Error getUnicoCliente-TOKEN: " + error);
        callbackError(error);
    });
}

export const getVentas = function (callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/pedpda/v1.0.0/all/" + login;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta getVentas: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error getVentas: " + error);
                sendWebhook(":x: Error getVentas (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error getVentas-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const getLineasVenta = function (cliente, pedido, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/lpedpda/v1.0.0/all/" + login + "/" + cliente + "/" + pedido;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta getLineasVenta: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error getLineasVenta: " + error);
                sendWebhook(":x: Error getLineasVenta (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error getLineasVenta-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const getUnicaVenta = function (cliente, pedido, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/pedpda/v1.0.0/" + login + "/" + cliente + "/" + pedido;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta getUnicaVenta: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error getUnicaVenta: " + error);
                sendWebhook(":x: Error getUnicaVenta (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error getUnicaVenta-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const crearVenta = function (cliente, tramite, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/pedpda/v1.0.0/" + login + "/" + cliente + "/" + tramite;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta crearVenta: " + JSON.stringify(responseJson));
                sendWebhook(":new: Creando Venta (" + url + "): ");
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error crearVenta: " + error);
                sendWebhook(":x: Error crearVenta (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error crearVenta-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const checkContenedor = function (contenedor, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/contene/v1.0.0/" + contenedor;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta checkContenedor: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error checkContenedor: " + error);
                sendWebhook(":x: Error checkContenedor (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error checkContenedor-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const postLineaPedido = function (cliente, pedweb, contenedor, articulo, cantidad, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/lpedpda/v1.0.0/" + login + "/" + cliente + "/" + pedweb + "/" + contenedor + "/" + articulo + "/" + cantidad;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta postLineaPedido: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error postLineaPedido: " + error);
                sendWebhook(":x: Error postLineaPedido (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error postLineaPedido-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const deleteLineaPedido = function (cliente, pedweb, contenedor, articulo, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/lpedpda/v1.0.0/" + login + "/" + cliente + "/" + pedweb + "/" + contenedor + "/" + articulo;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta deleteLineaPedido: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error deleteLineaPedido: " + error);
                sendWebhook(":x: Error deleteLineaPedido (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error deleteLineaPedido-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const getUnicaLineaVenta = function (cliente, pedweb, contenedor, articulo, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/lpedpda/v1.0.0/" + login + "/" + cliente + "/" + pedweb + "/" + contenedor + "/" + articulo;
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta getUnicaLineaVenta: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error getUnicaLineaVenta: " + error);
                sendWebhook(":x: Error getUnicaLineaVenta (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error getUnicaLineaVenta-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const terminarVenta = function (cliente, pedweb, callback, callbackError) {
    getPDA(function (PDA, login) {
        const url = "https://soa.domti.es/pedpda/v1.0.0/" + login + "/" + cliente + "/" + pedweb + "?e_pedweb=P";
        console.warn(url);
        getToken(function (token) {
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: reintentos
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta getUnicaLineaVenta: " + JSON.stringify(responseJson));
                sendWebhook(":white_check_mark: Terminando Venta (" + url + ")");
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error getUnicaLineaVenta: " + error);
                sendWebhook(":x: Error getUnicaLineaVenta (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            console.warn("Error getUnicaLineaVenta-TOKEN: " + error);
            callbackError(error);
        });
    });
}

export const alertTimeout = (Platform.OS.toLocaleLowerCase() === 'android' ? 0 : 550);
